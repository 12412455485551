import RepositoryFactory from "@/apis";
const payment = RepositoryFactory.payment;

export default {
  payment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.payment(payload);
        commit("SET_PAYMENT", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  registrationPayment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.registrationPayment(payload);
        // commit("SET_PAYMENT", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  awardsPayment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.awardsPayment(payload);
        // commit("SET_PAYMENT", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllPayments({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.getAllPayments(payload);
        commit("SET_ALL_PAYMENTS", data.data[0]);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllAwardPayment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.getAllAwardPayment(payload);
        // commit("SET_ALL_PAYMENTS", data.data[0]);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getPayULink({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.getPayULink(payload);
        commit("SET_PAYMENT_LINK", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getRegisterPayULink({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.getRegisterPayULink(payload);
        commit("SET_PAYMENT_LINK", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAwardsPayULink({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.getAwardsPayULink(payload);
        commit("SET_PAYMENT_LINK", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingle({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.getSingle(payload);
        commit("SET_PAYMENT", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  settleData({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.settleData(payload);
        // commit("SET_PAYMENT", data.data)
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updatePayment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.updatePayment(payload);
        // commit("SET_PAYMENT", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createEazypay({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.createEazypay(payload);
        commit("SET_PAYMENT", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getEazyPayLink({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await payment.getEazyPayLink(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
