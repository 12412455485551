import state from "../auth/state";

export default {
  UPDATE_CATEGORY_INFO(state, payload) {
    state.categories = payload;
  },
  UPDATE_SINGLE_CATEGORY_INFO(state, payload) {
    state.single_category = payload;
  },
  UPDATE_SUBCATEGORY_INFO(state, payload) {
    state.subcategories = payload;
  },
  UPDATE_BANNER_INFO(state, payload) {
    state.bannerState = payload;
  },
  UPDATE_TESTIMONIAL_INFO(state, payload) {
    state.testimonials = payload;
  },
  UPDATE_NEWS_INFO(state, payload) {
    state.newsState = payload;
  },
  UPDATE_GALLERY_INFO(state, payload) {
    state.galleryState = payload;
  },
  SINGLE_GALLERY_INFO(state, payload) {
    state.galleryCurrentState = payload;
  },
  UPDATE_TAXES_INFO(state, payload) {
    state.taxState = payload;
  },
  UPDATE_COUPONS_INFO(state, payload) {
    state.couponState = payload;
  },
  PRODUCT_FILTER_CATS(state, payload) {
    state.productFilterCats = paylaod;
  },
  UPDATE_DISPATCH_INFO(state, payload) {
    state.dispatchState = payload;
  },
  ADMIN_ALL_USERS(state, payload) {
    state.adminAllUsers = payload;
  },
  OTHERSTATE_dataa(state, payload) {
    state.dataa = payload;
  },
  NEWSLETTER_STATE(state, payload) {
    state.newsLetterState = payload;
  },
  CONTACT_US_STATE(state, payload) {
    state.contactUsState = payload;
  },
};
