export default {
  TOGGLE_POST_CREATE_SIDEBAR(state, payload) {
    state.PostCreateSidebar = payload;
    state.ChannelCreateSidebar = false;
  },
  TOGGLE_CHANNEL_CREATE_SIDEBAR(state, payload) {
    state.PostCreateSidebar = false;
    state.ChannelCreateSidebar = payload;
  },
  TOGGLE_REGISTER_POPUP(state, payload) {
    state.reg1popup = payload;
    if (payload) state.reg2popup = false;
  },
  TOGGLE_REGISTER_POPUP2(state, payload) {
    if (payload) {
      state.reg1popup = false;
    }

    state.reg2popup = payload;
  },
  OPEN_LOGIN_POP(state, payload) {
    state.loginPop = payload;
  },
};
