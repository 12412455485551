// import Vue from "vue";
// import authState from "../auth/state";

export default {
  SIGN_IN_WITH_GOOGLE(state, payload) {
    state[payload.key] = payload.value;
    // authState.user = payload.value;
  },
  SIGN_IN_WITH_FACEBOOK(state, payload) {
    state[payload.key] = payload.value;
  },
  SIGN_IN_WITH_LINKEDIN(state, payload) {
    state[payload.key] = payload.value;
  },
};
