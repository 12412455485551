import RepositoryFactory from "@/apis";
const author = RepositoryFactory.author;

export default {
  getAuthors({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await author.getAuthors(payload);

        commit("SET_AUTHOR_DETAIL", { key: "authors", value: data.data });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editAuthors({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await author.editAuthors(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAuthorByUserId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await author.getAuthorByUserId(payload);
        commit("SET_AUTHOR_DATA", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
