import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GETPRODTCTBYCATEGORYID = "getProductByCategoryId";
const GETSINGLEPRODUCT = "getSingle";

export default {
  GetProductByCategoryId(payload) {
    return Repository.post(transformRoute(GETPRODTCTBYCATEGORYID), payload);
  },
  GetSingleProductById(payload) {
    return Repository.post(transformRoute(GETSINGLEPRODUCT), payload);
  },
};

const transformRoute = (route) => {
  return `/ecommerce_ny/product/${route}`;
};
