import Repository from "@/apis/Repository.js";

const GET_ALL = "";
const SUBSCRIBE = "subscribe";

export default {
  getPageCategories(payload) {
    return Repository.get(transformRoute(GET_ALL), payload);
  },

  subscribeToNewsletter(payload) {
    return Repository.post(transformRoute(SUBSCRIBE), payload);
  },
};

const transformRoute = (route = "") => {
  return `/cms/page-category/${route}`;
};
