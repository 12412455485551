import RepositoryFactory from "@/apis";
const master = RepositoryFactory.master;

export default {
  getSubJobCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await master.getSubJobCategory(payload);
        commit("SET_INDUSTRY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getJobCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await master.getJobCategory(payload);
        commit("SET_INDUSTRY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createJobCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await master.createJobCategory(payload);
        commit("SET_INDUSTRY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateJobCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await master.updateJobCategory(payload);
        commit("SET_INDUSTRY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateSubJobCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await master.updateSubJobCategory(payload);
        commit("SET_INDUSTRY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteJobCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await master.deleteJobCategory(payload);
        commit("SET_INDUSTRY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteSubJobCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await master.deleteSubJobCategory(payload);
        commit("SET_INDUSTRY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createSubJobCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await master.createSubJobCategory(payload);
        commit("SET_INDUSTRY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getindustry({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await master.getindustry(payload);
        commit("SET_INDUSTRY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getsubindustry({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await master.getsubindustry(payload);
        commit("SET_SUBINDUSTRY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
