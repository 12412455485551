import state from "./state";
import mutations from "./mutation";
// import actions from "./action";

export default {
  namespaced: true,
  state: state,
  mutations: mutations
  // actions: actions
};
