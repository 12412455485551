import RepositoryFactory from "@/apis";
const benefits = RepositoryFactory.benefits;

export default {
  create({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await benefits.create(payload);

        resolve(data);
      } catch (error) {
        reject ( error.response.data)
      }
    });
  },

  update({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await benefits.update(payload);

        resolve(data);
      } catch (error) {
        reject ( error.response.data)
      }
    });
  },

  remove({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await benefits.remove(payload);

        resolve(data);
      } catch (error) {
        reject ( error.response.data)
      }
    });
  },

  getAll({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await benefits.getAll(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

};
