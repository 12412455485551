import Repository from "../Repository";

const ADD_ENTRY = "add";
// const DELETE_ENTRY = "delete";
// const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";

export default {
  getCollection() {
    return Repository.get(transformRoute(GET_ENTRY));
  },
  addCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/contactUs/${route}`;
};
