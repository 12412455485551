import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GETCATEGORY = "get";

export default {
  GetCategory(payload) {
    return Repository.get(transformRoute(GETCATEGORY), payload);
  },
};

const transformRoute = (route) => {
  return `/ecommerce_ny/category/${route}`;
};
