export default {
  GET_USER(state, payload) {
    state.user = payload;
  },
  SET_USER(state, payload) {
    state.user.result = payload;
  },
  GET_CHANNEL(state, payload) {
    state.channel = payload;
  },
  GET_POST(state, payload) {
    state.post = payload;
  },
  UPDATE_USER_INFO(state, payload) {
    if (payload.role) {
      state.user.result
        .filter((find) => find.mobile === payload.mobile)
        .filter((state) => (state.role = payload.role));
    } else {
      state.user.result
        .filter((find) => find.mobile === payload.mobile)
        .filter((state) => (state.isBlocked = payload.isBlocked));
    }
  },
  GET_ADMIN_MEMBERS(state, payload) {
    state.getAdminMembers = payload;
  },
  SEARCH_MEMBER(state, payload) {
    state.searchMember = payload;
  },
};
