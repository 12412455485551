import coupon from "../../apis/coupon";
import page from "../../apis/pages";
import pageCategory from "../../apis/page-category";
import filterTag from "../../apis/filterTag";
import career from "../../apis/career";
export default {
  createCoupon({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.create(payload);

        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  },
  update({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.update(payload);

        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  },
  getAllCoupon({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.getAll(payload);

        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  },
  verifyCoupon({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.verifyCoupon(payload);

        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  },
  email({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.email(payload);

        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  },
};
