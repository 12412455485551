export default [
  {
    path: "admin",
    name: "social-admin",
    component: () => import("../layouts/Admin.vue"),
    children: [
      {
        path: "home",
        name: "social-admin-home",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-home" */ "../pages/home/Home.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "campaign-list",
        name: "social-admin-campaign",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-campaign" */ "../pages/campaign-list/Campaigns.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-product",
        name: "social-admin-addproduct",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-addproduct" */ "../pages/add-product/AddProduct.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-page",
        name: "social-admin-page",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-page" */ "../pages/add-pages/AddPage.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-banner",
        name: "social-admin-addbanner",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-addbanner" */ "../pages/add-banner/AddBanner.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-tags",
        name: "social-admin-addtags",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-addtags" */ "../pages/add-tags/AddTags.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-category",
        name: "social-admin-addcategory",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-addcategory" */ "../pages/add-category/AddCategory.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },

      {
        path: "add-menu",
        name: "social-admin-menu",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-menu" */ "../pages/add-menu/AddMenu.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "sales-report",
        name: "social-admin-reports",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-reports" */ "../pages/sales-report/SalesReport.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "taxes",
        name: "social-admin-taxes",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-taxes" */ "../pages/taxes/Taxes.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "integration",
        name: "social-admin-integration",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-integration" */ "../pages/integration/Integration.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "campaigns/template",
        name: "social-admin-campaignemailer",
        component: () => import("../pages/campaign-list/CreateCampaign.vue"),
        children: [
          {
            path: "sms",
            name: "social-template-sms",
            component: () =>
              import(
                /* webpackChunkName: "social-template-sms" */ "../pages/campaign-list/sms/SMS.vue"
              ),

            meta: {
              rule: "editor",
              authRequired: true,
            },
          },
          {
            path: "sms",
            name: "social-template-email",
            component: () =>
              import(
                /* webpackChunkName: "social-template-email" */ "../pages/campaign-list/emailer/Emailer.vue"
              ),

            meta: {
              rule: "editor",
              authRequired: true,
            },
          },
          {
            path: "push-notification",
            name: "social-template-pushnotification",
            component: () =>
              import(
                /* webpackChunkName: "social-template-pushnotification" */ "../pages/campaign-list/push-notification/PushNotification.vue"
              ),

            meta: {
              rule: "editor",
              authRequired: true,
            },
          },
          {
            path: "whatsapp",
            name: "social-template-whatsapp",
            component: () =>
              import(
                /* webpackChunkName: "social-template-whatsapp" */ "../pages/campaign-list/whatsapp/Whatsapp.vue"
              ),

            meta: {
              rule: "editor",
              authRequired: true,
            },
          },
        ],
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "blog",
        name: "social-admin-blog",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-blog" */
             "../pages/blog/Blog.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "blog/add",
        name: "social-admin-add-blog",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-add-blog" */ "../pages/blog/Add.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
     
       


      {
        path: "Tag",
        name: "social-admin-tag",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-blog" */ "../pages/add-tags/Tag.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "Tag/add",
        name: "social-admin-add-tag",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-add-blog" */ "../pages/add-tags/AddTags.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
    ],
    meta: {
      rule: "editor",
      authRequired: true,
    },


    
  },
];
