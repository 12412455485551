import Repository from "./Repository";
import axios from "axios";

const formDataConfig = {
    headers: { "Content-Type" : "multipart/form-data" }
};

const SignInWithGoogle = "/auth/signinWithGoogle";
const SignInWithFacebook = "";
const SignInWithLinkedin = "";
const GET_AUTHURL = "/getAuthURL";
const GET_TOKEN = "/getToken";


export default{
    signInWithGoolge(payload){
        return Repository.post(`${SignInWithGoogle}`, payload);
    },
    signInWithFacebook(payload){
        return Repository.post(`${SignInWithFacebook}`, payload);
    },
    signInWithLinkedin(payload){
        return Repository.post(`${SignInWithLinkedin}`, payload);
    },
    getAuthUrl(payload) {
        return Repository.post(transformRoute(GET_AUTHURL), payload);
    },
    getToken(payload) {
        return Repository.post(transformRoute(GET_TOKEN), payload);
    },
}

const transformRoute = (route) => {
    return `/nhrdn/google${route}`;
  };
