import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" }
};

const author = "author";
const EDIT_AUTHOR = "author/edit";
const GET_AUTHOR_BY_USERID = "author/getAuthorByUserID";

export default {
  getAuthors(payload) {
    return Repository.get(transformRoute(author), payload);
  },
  editAuthors(payload) {
    return Repository.post(transformRoute(EDIT_AUTHOR), payload);
  },
  getAuthorByUserId(payload) {
    return Repository.post(transformRoute(GET_AUTHOR_BY_USERID), payload);
  }
};

const transformRoute = route => {
  return `/elearning/${route}`;
};
