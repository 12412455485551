export default {
  FORGOT_PASS(state, payload) {
    state[payload.key] = payload.value;
  },
  GET_OTP(state, payload) {
    state[payload.key] = payload.value;
  },
  UPDATE_USER_INFO(state, payload) {
    state.user = payload;
  },
  profileUpdate(state, payload) {
    state.updatedData = payload;
  },
  UPDATE_USER_COVER_IMAGE(state, payload) {
    state.user.coverImage = payload;
  },
  UPDATE_USER_IMAGE(state, payload) {
    state.user.userImage = payload;
  },
  userData(state, payload) {
    state.userData = payload;
  },
  userCart(state, payload) {
    state.user.student.cart.push(payload);
  },
  userCartDelete(state, payload) {
    state.user.student.cart.splice(payload, 1);
  },
  UPDATE_USERDATA_INFO(state, payload) {
    state.user.student.designation = payload.student.designation;
    state.user.student.location = payload.student.location;
    state.user.student.aboutMe = payload.student.aboutMe;
    state.user.mobile = payload.user.mobile;
    state.user.email = payload.user.email;
    state.user.firstname = payload.user.firstname;
    state.user.lastname = payload.user.lastname;
  },
  loginAction(state, payload) {
    state.loginAction = payload;
  },
  TICKER_DATA(state, payload) {
    state.ticker = payload;
  },
};
