import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const CREATE = "create";
const GET_ALL = "";
const GET_SINGLE = "getSingle";
const VERIFY = "verifyMembership";
const ADD_USERS = "addBulkUsers";
const verifyUser = "verifyUser";
const EMAIL = "email";
const GETALLREQUEST = "getAllRequest";
const UPDATE = "update";
const EDIT = "editMembership";

export default {
  verfication(payload) {
    return Repository.post(transformRoute(verifyUser), payload);
  },
  addMembership(payload) {
    return Repository.post(transformRoute(CREATE), payload);
  },
  editMembership(payload) {
    return Repository.post(transformRoute(EDIT), payload);
  },
  getAllMemberships(payload) {
    return Repository.get(transformRoute(GET_ALL), payload);
  },
  getSingleMemberships(payload) {
    return Repository.post(transformRoute(GET_SINGLE), payload);
  },
  verifyMembership(payload) {
    return Repository.post(transformRoute(VERIFY), payload);
  },
  addBulkUser(payload) {
    return Repository.post(transformRoute(ADD_USERS), payload);
  },
  email(payload) {
    return Repository.post(transformRoute(EMAIL), payload);
  },
  getAllRequest(payload) {
    console.log(payload);
    return Repository.post(transformRoute(GETALLREQUEST), payload);
  },
  update(payload) {
    return Repository.post(transformRoute(UPDATE), payload);
  },
};

const transformRoute = (route) => {
  return `/nhrdn/membership/${route}`;
};
