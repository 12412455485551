export default {
  SET_JOBS(state, payload) {
    state.allJobs = payload;
  },
  SET_SINGLEJOB(state, payload) {
    state.singleJob = payload;
  },
  SET_APPLIED_JOB(state, payload) {
    state.appliedJob = payload;
  },
  SET_OFFER_JOB(state, payload) {
    state.offerJob = payload;
  },
  SET_REQUEST_JOB(state, payload) {
    state.requestJob = payload;
  },
};
