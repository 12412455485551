import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const create = "create";
const createWithMedia = "createWithMedia";
const createPoll = "createPoll";
const votePoll = "votePoll";
const like = "like";
const unlike = "unlike";
const addComment = "addComment";
const addReply = "addReply";
const savePost = "save";
const likeComment = "likeComment";
const unlikeComment = "unlikeComment";
const unsavePost = "unsave";
const admindeletePost = "deletePost";
// const admindeletePost = "admindeletePost";
const deletePost = "deletePost";
const deleteAnyPost = "deleteAnyPost";
const deleteComment = "deleteComment";
const deleteReply = "deleteReply";
const LikeCounts = "LikeCounts";
const LikeCountUsers = "LikeCountUsers";
const viewPostLikes = "viewPostLikes";
const reportPost = "reportPost";
const editPost = "editPost";
const displayAllComment = "allComment";
export default {
  CreatePost(payload) {
    return Repository.post(transformRoute(create), payload);
  },
  admindeletePost(payload) {
    return Repository.post(transformRoute(admindeletePost), payload);
  },
  createWithMedia(payload) {
    return Repository.post(
      transformRoute(createWithMedia),
      payload,
      formDataConfig
    );
  },
  createPoll(payload) {
    return Repository.post(transformRoute(createPoll), payload);
  },
  votePoll(payload) {
    return Repository.post(transformRoute(votePoll), payload);
  },
  like(payload) {
    return Repository.post(transformRoute(like), payload);
  },
  unlike(payload) {
    return Repository.post(transformRoute(unlike), payload);
  },
  addComment(payload) {
    return Repository.post(transformRoute(addComment), payload);
  },
  addReply(payload) {
    return Repository.post(transformRoute(addReply), payload);
  },
  savePost(payload) {
    return Repository.post(transformRoute(savePost), payload);
  },
  likeComment(payload) {
    return Repository.post(transformRoute(likeComment), payload);
  },
  unlikeComment(payload) {
    return Repository.post(transformRoute(unlikeComment), payload);
  },
  unsavePost(payload) {
    return Repository.post(transformRoute(unsavePost), payload);
  },
  deletePost(payload) {
    return Repository.post(transformRoute(deletePost), payload);
  },
  deleteAnyPost(payload) {
    return Repository.post(transformRoute(deleteAnyPost), payload);
  },
  deleteComment(payload) {
    return Repository.post(transformRoute(deleteComment), payload);
  },
  deleteReply(payload) {
    return Repository.post(transformRoute(deleteReply), payload);
  },
  LikeCounts(payload) {
    return Repository.post(transformRoute(LikeCounts), payload);
  },
  LikeCountUsers(payload) {
    return Repository.post(transformRoute(LikeCountUsers), payload);
  },
  viewPostLikes(payload) {
    return Repository.post(transformRoute(viewPostLikes), payload);
  },
  reportPost(payload) {
    return Repository.post(transformRoute(reportPost), payload);
  },
  editPost(payload) {
    return Repository.post(transformRoute(editPost), payload);
  },

  displayAllComment(payload) {
    return Repository.post(transformRoute(displayAllComment), payload);
  },
};

const transformRoute = (route) => {
  return `/post/${route}`;
};
