import RepositoryFactory from "@/apis";
const google = RepositoryFactory.google;

export default {
  signInWithGoogle({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await google.signInWithGoolge(payload);

        localStorage.setItem("token", data.data.token);

        commit("SIGN_IN_WITH_GOOGLE", {key: "googleUserData" , value : data.data.user});

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  signInWithFacebook({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await google.signInWithFacebook(payload);

        localStorage.setItem("token", data.data.token);

        commit("SIGN_IN_WITH_FACEBOOK", {key: "facebookUserData" , value : "data.data"});

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  signInWithLinkedin({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await google.signInWithLinkedin(payload);

        localStorage.setItem("token", data.data.token);

        commit("SIGN_IN_WITH_LINKEDIN", {key: "linkedinUserData" , value : "data.data"});

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAuthUrl({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await google.getAuthUrl(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getToken({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await google.getToken(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

};
