import RepositoryFactory from "@/apis";
import { Decrypt } from "@/helpers/CryptoJS";
const product = RepositoryFactory.ecommerce1.product;
const products = RepositoryFactory.ecommerce1.products;
const sizeVariant = RepositoryFactory.ecommerce1.sizeVariant;
export default {
  GetProductByCategoryId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await products.GetProductByCategoryId(payload);
        // console.log(data);
        commit("SET_PRODUCT", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetSingleProductById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await products.GetSingleProductById(payload);
        // console.log("product", data);
        commit("SINGLE_PRODUCT_BY_ID", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ################################################################
  // -----------------  OTHERS -------------------------
  // ################################################################

  UPDATE_singleProductAdminStatee({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("UPDATE_singleProductAdminState", payload);
        resolve(payload);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    PRODUCTS  -----------------------------
  // ############################################################
  getProducts({ commit }, payload = "") {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.GetProduct(payload);
        commit("UPDATE_PRODUCTS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getMainAllCollection({ commit }, payload) {
    return new Promise(async (reslove, reject) => {
      try {
        const { data } = await product.getMainAllCollection(payload);
        commit("UPDATE_MAIN_All_PRODUCT_INFO", data.data);
        reslove(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  full_product({ commit }, payload) {
    return new Promise(async (reslove, reject) => {
      try {
        const { data } = await product.full_product(payload);
        // let decrypted = Decrypt(data.data);
        // commit("SET_PRODUCT", decrypted);
        commit("SET_PRODUCT", data.data);
        reslove(data);
        // reslove(decrypted);
      } catch (error) {
        reject(error);
      }
    });
  },
  getProductDetails({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.getOneCollection(payload);
        commit("UPDATE_SINGLE_PRODUCT_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  filterByCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.filterByCategory(payload);
        commit("UPDATE_PRODUCTS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  productAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  productEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  productDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ############################################################
  // -------------------    COLOR VARIANTSSS -----------------------------
  // ############################################################

  getColorVariant({ commit }, payload = null) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await colorVariant.getAllCollection(payload);
        commit("UPDATE_COLORVARIANTS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  colorVariantAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await colorVariant.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  colorVarianttEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await colorVariant.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  colorVariantDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await colorVariant.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    SIZE VARIANTSSS -----------------------------
  // ############################################################
  getSizeVariant({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.getAllCollection();
        commit("UPDATE_SIZEVARIANTS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSizeProdId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.getSizeProdId(payload);
        console.log("Single product", data);
        commit("UPDATE_SIZEVARIANTS_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  similarProductCollection({ commit }, paylaod) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.similarProductCollection();
        console.log(data);
        commit("UPDATE_SIMILAR_PRODUCTS", data.data); // all info with builin updated MRP, colorVariants,TaxDetails etc
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  },
  getAllPopulatedDetails({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.getAllPopulatedDetails();
        commit("UPDATE_ALL_INFO_PRODUCTS", data.data); // all info with builin updated MRP, colorVariants,TaxDetails etc
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  sizeVariantAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  sizeVarianttEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(payload);
        const { data } = await sizeVariant.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  sizeVariantDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    DIAMOND VARIANTS  -------------------
  // ############################################################
  getDiamondVariant({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await diamond.getAllVariants();
        commit("UPDATE_DIAMONDS_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  diamondVariantAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await diamond.addDiamond(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  diamondVarianttEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await diamond.editDiamond(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  diamondVariantDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await diamond.deleteDiamond(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
