import Repository from "../Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";
const GET_SINGLE_ENTRY = "getSingle";

export default {
  addCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editCategory(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteCollection(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getCategories(payload) {
    console.log("called");
    return Repository.get(transformRoute(GET_ENTRY), payload);
  },
  getSingleCategories(payload) {
    return Repository.post(transformRoute(GET_SINGLE_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/ecommerce_ny/category/${route}`;
};
