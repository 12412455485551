import Vue from "vue";
import router from "@/router";
import App from "./App.vue";
import JSONexcel from "vue-json-excel";
import VuePageTransition from "vue-page-transition";
import DashboardPlugin from "./plugins/dashboard-plugin";
import Gauth from "vue-google-oauth2";
// import VueSweetalert2 from "vue-sweetalert2";
// import "sweetalert2/dist/sweetalert2.min.css";
// Vue.use(VueSweetalert2, options);
const gauthOption = {
  clientId:
    "362882738550-lo7fqiuhdmdqioao1k76i342eudfgupv.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "consent",
  fetch_basic_profile: true,
};
Vue.use(Gauth, gauthOption);
Vue.component("downloadExcel", JSONexcel);
import ElementUI from "element-ui";
Vue.use(ElementUI);
import "element-ui/lib/theme-chalk/index.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..

import "@/assets/scss/argon.scss";
import "@/assets/scss/Index.scss";
import "@/pages/e-learning/scss/index.scss";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
// import FBSignInButton from 'vue-facebook-signin-button';
// Vue.use(FBSignInButton);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
AOS.init();
Vue.use(VuePageTransition);
import VueSweetalert2 from "vue-sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";
const options = {
  confirmButtonColor: `rgba(var(--vs-primary), 1)`,
  cancelButtonColor: "#ff7674",
};
Vue.use(VueSweetalert2, options);

// import "@/core-components/assets/scss/index.scss";
// import MenuIcon from "vue-material-design-icons/Menu.vue";
// import "vue-material-design-icons/styles.css";
// Vue.component("menu-icon", MenuIcon);
// If you don't need the styles, do not connect
// import "boxicons/css/boxicons.min.css";
import Vuesax from "vuesax";
// import VueMaterial from "vue-material";
// import "vue-material/dist/vue-material.min.css";
// import "vue-material/dist/theme/default.css";
import apollo from "./apolloClient";
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);
// Vue.use(VueMaterial);
// import VueQuillEditor from "vue-quill-editor";

// import "quill/dist/quill.core.css"; // import styles
// import "quill/dist/quill.snow.css"; // for snow theme
// import "quill/dist/quill.bubble.css"; // for bubble theme

// Vue.use(VueQuillEditor /* { default global options } */);
import "vuesax/dist/vuesax.css"; //Vuesax styles
Vue.use(Vuesax, {
  // options here
});
require("vue2-animate/dist/vue2-animate.min.css");
// Vue.use(VueSweetalert2);
import "./assets/scss/tailwind.css";
Vue.config.productionTip = false;
Vue.prototype.$apollo = apollo;
// Global variables
Vue.prototype.$imageURL = process.env.VUE_APP_IMAGE_URL;
Vue.prototype.$apiUrl = process.env.VUE_APP_BASE_URL;
Vue.prototype.$homeAuth = process.env.VUE_APP_REDIRECT_HOME;
// Vuex Store
import store from "./store/index.js";
// global loader
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(VueLoading);

// plugin setup
Vue.use(DashboardPlugin);
import "./vee-validate.js";
new Vue({
  store,
  router,
  Vuesax,
  render: (h) => h(App),
}).$mount("#app");
