export default {
  SET_LISTINGS(state, payload) {
    state.listings = payload;
  },

  SET_PAGES(state, payload) {
    state.pages = payload;
  },
  NATIONAL_SECRETARIAT_DATA(state, payload) {
    state.nationalSecretariat = payload;
  },
  BOARD_MEMBERS_DATA(state, payload) {
    state.boardMember = payload;
  },
  YOUNG_TALENT_COUNCIL_DATA(state, payload) {
    state.youngTalentCouncil = payload;
  },
  PAST_NATIONAL_DATA(state, payload) {
    state.pastNationalPresidents = payload;
  },
  MENTOR_DATA(state, payload) {
    state.mentor = payload;
  },
  GATT_DATA(state, payload) {
    state.gatt = payload;
  },
  SPECIAL_INVITIES_DATA(state, payload) {
    state.specialInvities = payload;
  },
  NATIONAL_STRATEGIC_GROUP_DATA(state, payload) {
    state.nationalStrategicGroup = payload;
  },
  CHAPTER_EXCELLENCE_GROUP_DATA(state, payload) {
    state.chapterExcellenceGroup = payload;
  },
  SET_CATEGORYWISEPAGE(state, payload) {
    state.categorywisepages = payload;
  },
  SET_NEWSLETTERS(state, payload) {
    state.newsletters = payload;
  },
  SET_BLOGPAGES(state, payload) {
    state.blogPages = payload;
  },
  SET_PODCASTPAGES(state, payload) {
    state.podcastPages = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },

  SET_PAGE_CATEGORIES(state, payload) {
    state.pageCategories = payload;
  },

  SET_CATEGORIES_TAGS(state, payload) {
    state.filtertags = payload;
  },

  SET_FILTERS(state, payload) {
    state.filters = payload;
  },
  // SET_FILTER_TAGS(state, payload) {
  //   state.filtertags = payload;
  // },
  DELETE_PAGE(state, payload) {
    const index = state.categorywisepages.pages.findIndex(
      (page) => page._id === payload._id
    );
    state.categorywisepages.pages.splice(index, 1);
    state.categorywisepages.totalLength -= 1;
  },
  SET_SELECTED_BLOG(state, payload) {
    state.selectedBlog = payload;
  },
  SET_SELECTED_NEWSLETTER(state, payload) {
    state.selectedNewsletter = payload;
  },
  SET_SELECTEDPAGECATEGORY(state, payload) {
    state.selectedpagecategory = payload;
  },
  GET_HOME_PAGE_DATA(state, payload) {
    state.homePage = payload;
  },
  GET_LEADERSHIP_DATA(state, payload) {
    state.leadership = payload;
  },
  GET_ALL_HOME_PAGE_DATA(state, payload) {
    state.homePageAllData = payload;
  },
  // ADD_PAGE(state, payload) {
  //   state.categorywisepages.unshift(payload);
  // },
  SET_CAREERS(state, payload) {
    state.careers = payload;
  },
  SET_SELECTED_CAREER(state, payload) {
    state.selectedCareer = payload;
  },
  EDIT_PAGE(state, payload) {
    const index = state.categorywisepages.findIndex(
      (page) => page._id === payload._id
    );
    state.categorywisepages.splice(index, 1, payload);
  },
  DELETE_PODCAST(state, payload) {
    let index = state.podcastPages.pages.findIndex((x) => x._id == payload._id);
    state.podcastPages.pages.splice(index, 1);
    state.podcastPages.totalLength -= 1;
  },
  DELETE_BLOG(state, payload) {
    let index = state.blogPages.pages.findIndex((x) => x._id == payload._id);
    state.blogPages.pages.splice(index, 1);
    state.blogPages.totalLength -= 1;
  },
};
