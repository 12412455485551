// import AdminRoutes from "../admin/routes";
import AdminRoutes from "../admin/routes";

export default [
  {
    path: "/social",
    name: "social",
    component: () => import("../Social.vue"),
    children: [...AdminRoutes],
    meta: {
      rule: "editor",
      authRequired: true
    }
  }
];
