import RepositoryFactory from "@/apis";
const network = RepositoryFactory.network;
const user = RepositoryFactory.user;

export default {
  networkrequest({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const myuserid = rootState.auth.user._id;
        let data = {};
        if (payload.status == "pending") {
          Object.assign(data, { initiaterid: myuserid });
          Object.assign(data, { receiverid: payload.receiverid });
        }
        if (payload.status == "accepted" || payload.status == "rejected") {
          Object.assign(data, { initiaterid: payload.initiaterid });
          Object.assign(data, { receiverid: myuserid });
        }
        Object.assign(data, { status: payload.status });
        // console.log(myuserid);
        const { result } = await network.networkrequest(data);
        // commit("SET_ALL_MEMBERS", data.data);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },
  getrequest({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const myuserid = rootState.auth.user._id;
        const { data } = await network.getrequest({
          receiverid: myuserid,
          ...payload,
        });
        commit("SET_ALL_REQUEST", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getconnection({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const myuserid = rootState.auth.user._id;
        const { data } = await user.getSingleUser({
          userid: myuserid,
          ...payload,
        });
        commit("SET_ALL_CONNECTION", data.data[0].connectionlist);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getsinglerequest({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await network.getsinglerequest(payload);
        // commit("SET_ALL_MEMBERS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteconnection({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const myuserid = rootState.auth.user._id;
        const { data } = await network.deleteconnection({
          userid: myuserid,
          ...payload,
        });
        // commit("SET_ALL_MEMBERS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
