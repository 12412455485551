import {
  required,
  confirmed,
  length,
  email,
  alpha,
  regex,
  numeric,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("requiredEmail", {
  ...required,
  message: "Email is required",
});

extend("requiredPassword", {
  ...required,
  message: "Password is required",
});

extend("email", {
  ...email,
  message: "Valid Email is Required",
});

extend("confirmed", {
  ...confirmed,
  message: "This field confirmation does not match",
});
extend("confirmPass", {
  ...confirmed,
  message: "Password & confirm password does not match",
});

extend("length", {
  ...length,
  message: "This field must have 2 options",
});
extend("alpha", {
  ...alpha,
  message: "This field must have only alphabates",
});
extend("numeric", {
  ...numeric,
  message: "This field must have only numbers",
});
extend("regex", {
  ...regex,
  message: "This field format is not valid",
});
extend("url", {
  ...regex,
  message: "Invalid url format",
});
extend("password", {
  ...regex,
  message:
    "Password must contain atleast 1 Uppercase, 1 Lowercase, 1 Special Character and 1 Number",
});
