import RepositoryFactory from "@/apis";
const auth = RepositoryFactory.auth;
const ecommerce = RepositoryFactory.ecommerce;
const userAuth = RepositoryFactory.UserAuth;
export default {
  signupWithPassword({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.signupWithPassword(payload);

        // Set accessToken
        localStorage.setItem("token", data.data.token);

        // Update user details
        commit("UPDATE_USER_INFO", data.data.user);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  generateOTP({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.generateOTP(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  loginWithPassword({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await ecommerce.loginWithPassword(payload);
        // Set accessToken
        localStorage.setItem("token", data.data.token);

        // Set accessToken
        // localStorage.setItem("dotnetToken", data.data.dotnetToken);

        // Update user details
        commit("UPDATE_USER_INFO", data.data.user);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  VerifyOTP({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.VerifyOTP(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  forgotPassword({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.forgotPassword(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  logout({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.logout(payload);

        await commit("logout", null, { root: true });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getCurrentUser({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await userAuth.getUserDetails();
        commit("UPDATE_USER_INFO", data.data);

        resolve(data);
      } catch (error) {
        if (
          error.response.data.message.includes(
            "No authorization token was found"
          ) &&
          Object.keys(store.state.authNew.user).length > 0
        )
          await commit("logout", null, { root: true });
        // if (
        //   error.response.data.message.includes(
        //     "No authorization token was found"
        //   ) &&
        //   Object.keys(store.state.authNew.user).length > 0
        // )
        //   await commit("logout", null, { root: true });
        reject(error);
      }
    });
  },
  UpdateUserDetails({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.UpdateUserDetails(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateUserProfileDetails({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.UpdateUserProfileDetails(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
