// import jwt from "jsonwebtoken";
import auth from "@/store";

export default [
  {
    path: "/channel",
    name: "channel",
    component: () => import("@/channel/Channel.vue"),
    beforeEnter: (to, from, next) => {
      // const user = jwt.decode(localStorage.getItem("token"));
      if (auth.state.auth.user && Object.keys(auth.state.auth.user).length) {
        next();
      } else {
        auth.state.UI.loginPop = true;
        next({ name: "home" });
      }
    },
    meta: {
      pageTitle: "channel",
      rule: "editor",
      authRequired: true,
    },
  },
  {
    path: "/joindiscussion",
    name: "joindiscussion",
    component: () => import("@/channel/components/JoinDisucssion.vue"),
    meta: {
      pageTitle: "Community",
      rule: "editor",
      authRequired: true,
    },
  },
  {
    path: "/pricing-details",
    name: "pricing",
    component: () => import("@/pricing/PricingDetails.vue"),
    meta: {
      pageTitle: "pricing",
      rule: "editor",
      authRequired: true,
    },
  },
];
