import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const CREATE = "create";
const GET_ALL = "";
const PAYU = "payu";
const GET_SINGLE = "getSingle";
const UPDATE_PAYMENT = "updatePayment";
const REGISTERPAYMENT = "CreateRegister";
const REGISTERPAYU = "registerPayU";
const SETTLE_DATA = "settleData";
const AWARDSPAYMENT = "CreateAwards";
const AWARDSPAYU = "awardsPayU";
const GET_ALL_AWARDS_PAYMENT = "getAllAwardPayment";
const CREATE_EASYPAY = "createEazypay";
const GET_EASYPAY_LINK = "getEazyPayLink";

export default {
  payment(payload) {
    return Repository.post(transformRoute(CREATE), payload);
  },
  registrationPayment(payload) {
    return Repository.post(transformRoute(REGISTERPAYMENT), payload);
  },
  awardsPayment(payload) {
    return Repository.post(transformRoute(AWARDSPAYMENT), payload);
  },
  getAllPayments(payload) {
    return Repository.post(transformRoute(GET_ALL), payload);
  },
  getAllAwardPayment(payload) {
    return Repository.post(transformRoute(GET_ALL_AWARDS_PAYMENT), payload);
  },
  getPayULink(payload) {
    return Repository.post(transformRoute(PAYU), payload);
  },
  getRegisterPayULink(payload) {
    return Repository.post(transformRoute(REGISTERPAYU), payload);
  },
  getAwardsPayULink(payload) {
    return Repository.post(transformRoute(AWARDSPAYU), payload);
  },
  getSingle(payload) {
    return Repository.post(transformRoute(GET_SINGLE), payload);
  },
  settleData(payload) {
    return Repository.post(transformRoute(SETTLE_DATA), payload);
  },
  updatePayment(payload) {
    return Repository.post(transformRoute(UPDATE_PAYMENT), payload);
  },
  createEazypay(payload) {
    return Repository.post(transformRoute(CREATE_EASYPAY), payload);
  },
  getEazyPayLink(payload) {
    return Repository.post(transformRoute(GET_EASYPAY_LINK), payload);
  },
};

const transformRoute = (route) => {
  return `/nhrdn/payment/${route}`;
};
