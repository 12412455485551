<template>
  <div>
    <router-link to="/chatboat">
      <!-- <b-button 
    @click="handleRoute()"
      pill
      variant="primary"
      class="fixed z-40 bottom-12 right-8 py-4 px-8 mob-hidden"
      ><a href="mailto:membersupport@nationalhrd.org" class="hover:text-white"
        >Help us get better!</a
      ></b-button
    > -->

      <b-button
        @click="handleRoute()"
        pill
        variant="primary"
        class="fixed z-40 bottom-5 left-8 py-4 px-8 mob-hidden"
        >Help us get better!</b-button
      >
      <b-button
        pill
        variant="primary"
        class="fixed z-40 bottom-12 right-8 py-4 lg:px-8 px-4 lg:hidden flex"
      >
        <a href="mailto:membersupport@nationalhrd.org" class="hover:text-white"
          ><feather-icon icon="MessageSquareIcon" /></a
      ></b-button>
      <!-- "https:&#x2F;&#x2F;storage.googleapis.com&#x2F;brained-prod&#x2F;nhrdn&#x2F;all-in-one&#x2F;Frame%202608803.png" -->
      <df-messenger
        chat-icon="https://storage.googleapis.com/brained-prod/nhrdn/all-in-one/Frame%202608803.png"
        chat-title="NHRDN"
        agent-id="76d295c8-e049-4355-842e-cbbee669c1d0"
        language-code="en"
      ></df-messenger>
    </router-link>
    <router-view />
  </div>
</template>

<script>
// import API from "@/apis";

import gql from "graphql-tag";
export default {
  data() {
    return {};
  },
  methods: {
    handleRoute() {
      window.open("mailto:membersupport@nationalhrd.org", "_blank");
    },
  },
  async mounted() {
    // const response = await this.$apollo.query({
    //   query: gql`
    //     query {
    //       allUsers {
    //         id
    //         email
    //       }
    //     }
    //   `,
    // });
    // console.log(response.data.allUsers);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
df-messenger {
  --df-messenger-bot-message: #8f948f;
  --df-messenger-font-color: white;
  --df-messenger-button-titlebar-color: #d44242;
  --df-messenger-user-message: #479b3d;
}
#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.g-cursor {
  &_hide {
    opacity: 0;
    width: 60px;
    height: 60px;
    transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
    position: absolute;
  }

  &__circle {
    pointer-events: none;
    user-select: none;
    top: 0;
    left: 0;
    position: fixed;
    width: 30px;
    height: 30px;
    border: 2px solid #e74747;
    border-radius: 100%;
    z-index: 5555;
    backface-visibility: hidden;
    transition: opacity 0.6s ease;
  }

  &__point {
    top: 0;
    left: 0;
    position: fixed;
    width: 10px;
    height: 10px;
    pointer-events: none;
    user-select: none;
    border-radius: 100%;
    background: #e74747;
    z-index: 55555555;
    backface-visibility: hidden;
    will-change: transform;
  }

  &_hover {
    .g-cursor__circle {
      opacity: 0;
      width: 60px;
      height: 60px;
      transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
    }
  }
}
</style>
