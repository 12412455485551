import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const CREATE = "create";
const REGISTERCREATE = "registerCreate";

export default {
  addOrganisation(payload) {
    return Repository.post(transformRoute(CREATE), payload);
  },
  addJobOrganisation(payload) {
    return Repository.post(transformRoute(CREATE), payload, formDataConfig);
  },
  registerAddOrganisation(payload) {
    return Repository.post(transformRoute(REGISTERCREATE), payload);
  },
};

const transformRoute = (route) => {
  return `/nhrdn/organisation/${route}`;
};
