import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GET = "getpagecategory";
const GETSINGLEPAGECATEGORY = "getSinglepagecategory";
const GETPAGECATEGORYBYID = "getpagecategorybyid";
export default {
  GetPageCategory(payload) {
    return Repository.get(transformRoute(GET), payload);
  },

  GetSinglePageCategory(payload) {
    return Repository.post(transformRoute(GETSINGLEPAGECATEGORY), payload);
  },
  GetPageCategoryById(payload) {
    return Repository.post(transformRoute(GETPAGECATEGORYBYID), payload);
  },
};

const transformRoute = (route) => {
  return `/pagecategory/${route}`;
};
