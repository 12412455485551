import RepositoryFactory from "@/apis";
const placeOrder = RepositoryFactory.placeOrder;

export default {
  placeOrder({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await placeOrder.placeOrder(payload);
        // console.log(data);
        console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateStatus({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await placeOrder.updateStatus(payload);
        // console.log(data);
        console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
};
