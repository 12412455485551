import RepositoryFactory from "@/apis";
const awards = RepositoryFactory.awards;

export default {
  create({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await awards.create(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAll({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await awards.getAll(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingle({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await awards.getSingle(payload);

        commit("SET_INVOICE_DETAILS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  update({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await awards.update(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  downloadExcel({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await awards.downloadExcel(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
