import RepositoryFactory from "@/apis";
const learning = RepositoryFactory.learningTracker;

export default {
  AddLearningData({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await learning.AddLearningData(payload);
        // console.log(data);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateLearning({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await learning.updateLearning(payload);
        // console.log(data);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingle({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await learning.getSingle(payload);
        // console.log(data);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getCourseByStudentId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await learning.getCourseByStudentId(payload);
        // console.log(data);
        commit("SET_ATTENDEES", data.data);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
