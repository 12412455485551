import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const CREATE = "/nhrdn/registration/create";
const BULKCREATE = "/nhrdn/registration/bulkCreate";
const GETDATA = "/nhrdn/registration/getAll";
const GETPAYMENTDATA = "/nhrdn/payment/getAllRegistrationPayment";
const VERIFY = "/nhrdn/registration/verify";
const GETSINGLE = "/nhrdn/registration/getSingle";
const BULKUPLOAD = "/nhrdn/registration/bulkUpload";
const DOWNLOADEXCEL = "/nhrdn/registration/downloadExcel";
const UPDATE = "/nhrdn/registration/update";
const GETALLAGENDAIMAGES = "/nhrdn/registration/getAllAgendaImages";
const getAllPartnerImages = "/nhrdn/registration/getAllPartnerImages";

export default {
  create(payload) {
    return Repository.post(`${CREATE}`, payload);
  },
  bulkCreate(payload) {
    return Repository.post(`${BULKCREATE}`, payload);
  },
  getData(payload) {
    return Repository.post(`${GETDATA}`, payload);
  },
  update(payload) {
    return Repository.post(`${UPDATE}`, payload);
  },
  getSingle(payload) {
    return Repository.post(`${GETSINGLE}`, payload);
  },
  getRegisterPaymnetData(payload) {
    return Repository.post(`${GETPAYMENTDATA}`, payload);
  },
  verify(payload) {
    return Repository.post(`${VERIFY}`, payload);
  },
  bulkUpload(payload) {
    return Repository.post(`${BULKUPLOAD}`, payload);
  },
  downloadExcel(payload) {
    return Repository.post(`${DOWNLOADEXCEL}`, payload);
  },
  getAllAgendaImages(payload) {
    return Repository.post(`${GETALLAGENDAIMAGES}`, payload);
  },
  getAllPartnerImages(payload) {
    return Repository.post(`${getAllPartnerImages}`, payload);
  },
};
