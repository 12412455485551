import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const ADD_CART = "cart/add";
const REMOVE_CART = "cart/remove";
const GET_CART = "cart/";
const REMOVE_ITEMS = "cart/removeMany";

export default {
  AddCart(payload) {
    return Repository.post(transformRoute(ADD_CART), payload);
  },
  removeCart(payload) {
    return Repository.post(transformRoute(REMOVE_CART), payload);
  },
  getCartData(payload) {
    return Repository.post(transformRoute(GET_CART), payload);
  },
  removeItems(payload) {
    return Repository.post(transformRoute(REMOVE_ITEMS), payload);
  },
};

const transformRoute = (route) => {
  return `${route}`;
};
