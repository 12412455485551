export default {
  SET_ALL_PAYMENTS(state, payload) {
    state.allPayments = payload;
  },
  SET_PLAN_DATA(state, payload) {
    state.planData = payload;
  },
  SET_PAYMENT_LINK(state, payload) {
    state.paymentLink = payload;
  },
  SET_PAYMENT(state, payload) {
    state.payment = [{ ...payload }];
  },
  SET_VIEW_MEMBERSHIP(state, payload) {
    state.viewMembership = payload;
  },
};
