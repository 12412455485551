import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const networkrequest = "request";
const getrequest = "getrequest";
const getsinglerequest = "getsinglerequest";
const deleteconnection = "deleteconnection";

export default {
  networkrequest(payload) {
    return Repository.post(transformRoute(networkrequest), payload);
  },
  getrequest(payload) {
    return Repository.post(transformRoute(getrequest), payload);
  },
  getsinglerequest(payload) {
    return Repository.post(transformRoute(getsinglerequest), payload);
  },
  deleteconnection(payload) {
    return Repository.post(transformRoute(deleteconnection), payload);
  },
};

const transformRoute = (route) => {
  return `nhrdn/user/${route}`;
};
