import Repository from "../Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";

export default {
  addCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  updateCollection(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteCollection(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getCollection(payload) {
    return Repository.get(transformRoute(GET_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/testimonials/${route}`;
};
