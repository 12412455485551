import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const GETALL = "";
const GETSINGLE = "";
const CREATE = "create";
const UPDATE = "edit";

export default {
  getAlMemberships(payload) {
    return Repository.get(transformRoute(GETALL), payload);
  },
  getSingleMembership(payload) {
    return Repository.get(transformRoute(GETSINGLE + "/" + payload));
  },
  create(payload) {
    return Repository.post(transformRoute(CREATE), payload);
  },
  update(payload) {
    return Repository.post(transformRoute(UPDATE), payload);
  },
};

const transformRoute = (route) => {
  return `/nhrdn/plan/${route}`;
};
