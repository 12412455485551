import RepositoryFactory from "@/apis";
const wishlist = RepositoryFactory.wishlist;

export default {
    AddWishlist({}, payload) {
        console.log(payload);
        return new Promise(async (resolve, reject) => {
            try {
            const { data } = await wishlist.AddWishlist(payload);
            console.log(data);
            
            // console.log(payload);
            resolve(data);
            } catch (error) {
            reject(error);
            }
        });
    },
    getWishlistData({ commit },payload){
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await wishlist.getWishlistData(payload);
        
                commit("getWishlistData",data.data);
        
                resolve(data);
            } catch (error) {
                reject(error);
            }
            });
        },

    removeWishlist({  },payload){
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await wishlist.removeWishlist(payload);
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }
}