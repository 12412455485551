export default {
  UNIVERSAL_POPUP_TOGGLE(state, payload) {
    state.universalPopUpToggle = payload;
  },
  //   Products
  OPEN_ORDER_RETURN_POPUP(state, payload) {
    state.OrderReturnImagePopupToggle = true;
    state.OrderReturnImagePopupState = payload;
  },
  CLOSE_ORDER_RETURN_POPUP(state) {
    state.OrderReturnImagePopupToggle = false;
    state.OrderReturnImagePopupState = {};
  },
  ORDER_RETURN_VIDEO_POPUP_OPEN(state, payload) {
    state.OrderReturnVideoPopupToggle = payload;
  },
  REJECTED_REASON_POPUP_TOGGLE(state, payload, payload2 = []) {
    state.rejectedReasonToggle = payload;
    if (!!payload) state.rejectedReasonState = payload2;
    if (!payload) state.rejectedReasonState = [];
  },
  DISPATCHER_POPUP(state, payload) {
    state.dispatcherToggle = payload;
  },
  PERSONAL_DETAILS_POPUP(state, payload) {
    state.personalDetailsToggle = payload;
  },
};
