import state from "./state";

export default {
  SET_PRODUCT(state, payload) {
    state.product = payload;
  },
  GET_SINGLE_PRODUCT_BREADCRUMS(state, payload) {
    state.breadcrums = payload;
  },
  SINGLE_PRODUCT_BY_ID(state, payload) {
    state.SingleProductById = payload;
  },
  UPDATE_PRODUCTS_INFO(state, payload) {
    state.productState = payload;
  },
  UPDATE_DIAMONDS_INFO(state, payload) {
    state.diamondsState = payload;
  },
  REMOVE_DIAMONDS_VARIANTS(state, payload) {
    state.diamondsState = state.diamondsState.filter(
      (_) => !payload.includes(_._id)
    );
  },
  ADD_DIAMONDS_VARIANTS(state, payload) {
    if (!state.diamondsState.includes(payload)) {
      state.diamondsState.push(payload);
    }
  },
  UPDATE_MAIN_All_PRODUCT_INFO(state, payload) {
    state.mainProductState = payload;
  },
  UPDATE_SINGLE_PRODUCT_INFO(state, payload) {
    state.singleProductState = payload;
  },
  UPDATE_COLORVARIANTS_INFO(state, payload) {
    state.colorVariantState = payload;
  },
  UPDATE_SIZEVARIANTS_INFO(state, payload) {
    state.sizeVariantState = payload;
  },
  UPDATE_singleProductAdminState(state, payload) {
    state.singleProductAdminState = payload;
  },
  UPDATE_ALL_INFO_PRODUCTS(state, payload) {
    state.allDetailsOfProduct = payload;
  },
  UPDATE_SIMILAR_PRODUCTS(state, payload) {
    state.similarProducts = payload;
  },
};
