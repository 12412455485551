import RepositoryFactory from "@/apis";
const admin = RepositoryFactory.admin;
const auth = RepositoryFactory.auth;

export default {
  setUsers({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // Update user details
        // const { data } = await admin.getUsers();
        commit("SET_USER", payload);
        resolve(payload);
      } catch (error) {
        reject(error);
      }
    });
  },
  getUsers({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await admin.getUsers(payload);

        // Update user details
        commit("GET_USER", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getChannel({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await admin.getChannel(payload);

        // Update user details
        commit("GET_CHANNEL", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getPost({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await admin.getPost(payload);

        // Update user details
        commit("GET_POST", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateUserDetails({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.UpdateUserDetails(payload);
        // Update user details
        commit("UPDATE_USER_INFO", payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAdminMembers({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await admin.getAdminMembers(payload);
        commit("GET_ADMIN_MEMBERS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  searchMember({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await admin.searchMember(payload);
        commit("SEARCH_MEMBER", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
