import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const CREATE = "create";
const GETALL = "getAll";
const VERIFYCOUPON = "verifyCoupon";
const EMAIL = "freeCorporateRegistrationMail";
const UPDATE = "update";

export default {
  create(payload) {
    return Repository.post(transformRoute(CREATE), payload);
  },
  update(payload) {
    return Repository.post(transformRoute(UPDATE), payload);
  },
  getAll(payload) {
    return Repository.post(transformRoute(GETALL), payload);
  },
  verifyCoupon(payload) {
    return Repository.post(transformRoute(VERIFYCOUPON), payload);
  },
  email(payload) {
    return Repository.post(transformRoute(EMAIL), payload);
  },
};

const transformRoute = (route) => {
  return `/nhrdn/coupon/${route}`;
};
