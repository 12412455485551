import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const ADDPAGE = "addpage";
const GET = "getpage";
const GETSINGLEPAGE = "getSinglpage";
const GETPAGEBYID = "getpagesbyid";
const EDITPAGES = "updatepage";
const INSERTPAGE = "insertpage";

const GETPODCASTBYID = "getpodcastpagesbyid";
const REMOVEPAGES = "RemovePages";
export default {
  AddPage(payload) {
    getsingle;
    return Repository.post(transformRoute(ADDPAGE), payload);
  },

  GetPage(payload) {
    return Repository.get(transformRoute(GET), payload);
  },

  GetSinglePage(payload) {
    return Repository.post(transformRoute(GETSINGLEPAGE), payload);
  },
  GetPageById(payload) {
    return Repository.post(transformRoute(GETPAGEBYID), payload);
  },
  GetPodCastPageById(payload) {
    return Repository.post(transformRoute(GETPODCASTBYID), payload);
  },
  EditPages(payload) {
    return Repository.post(transformRoute(EDITPAGES), payload);
  },
  RemovePages(payload) {
    return Repository.post(transformRoute(REMOVEPAGES), payload);
  },
  insertPage(payload) {
    return Repository.post(transformRoute(INSERTPAGE), payload);
  },
};

const transformRoute = (route) => {
  return `/page/${route}`;
};
