import Repository from "@/apis/Repository.js";
import { Encript } from "@/helpers/CryptoJS";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const GET = "";
const CREATE = "/nhrdn/newsletter/create";
const GETALL = "/nhrdn/newsletter/getAll";
const DELETE = "/nhrdn/newsletter/delete";
const UPDATE = "/nhrdn/newsletter/update";
const GETHOMEPAGEDATA = "/nhrdn/cms/getPrevious";
const GETALLHOMEPAGEDATA = "/nhrdn/cms/getAll";
const CREATEALLHOMEPAGEDATA = "/nhrdn/cms/create";
const PUBLISH = "/nhrdn/cms/update";
const CREATELEADERSHIP = "/nhrdn/leadership/create";
const GETLEADERSHIPDATA = "/nhrdn/leadership/getAll";
const UPDATELEADERSHIP = "/nhrdn/leadership/update";
const LINKGENERATOR = "/nhrdn/cms/linkGenerator";
const Brochure = "/nhrdn/cms/addBrochure";
const GETALLLINK = "/nhrdn/cms/getAlllink";
const getBrochure = "/nhrdn/cms/getBrochure";
const DELETELINK = "/nhrdn/cms/deleteLink";

export default {
  getListings() {
    return Repository.get(transformRoute(GET));
  },
  getHomePageData(payload) {
    return Repository.post(`${GETHOMEPAGEDATA}`, payload);
  },
  deleteLink(payload) {
    return Repository.post(`${DELETELINK}`, payload);
  },
  linkGenerator(payload) {
    return Repository.post(
      `${LINKGENERATOR}`,
      // Encript(JSON.stringify(payload))
      payload,
      formDataConfig
    );
  },
  Brochure(payload) {
    return Repository.post(`${Brochure}`, payload);
  },
  getBrochure(payload) {
    return Repository.post(`${getBrochure}`, payload);
  },
  getAllLink(payload) {
    return Repository.post(`${GETALLLINK}`, payload);
  },
  getAllLeadershipData(payload) {
    return Repository.post(`${GETLEADERSHIPDATA}`, payload);
  },
  updateLeadership(payload) {
    return Repository.post(`${UPDATELEADERSHIP}`, payload);
  },
  CreateHomePageData(payload) {
    return Repository.post(`${CREATEALLHOMEPAGEDATA}`, payload, formDataConfig);
  },
  Publish(payload) {
    return Repository.post(`${PUBLISH}`, payload);
  },
  createLeadership(payload) {
    return Repository.post(`${CREATELEADERSHIP}`, payload);
  },
  getAllHomePageData(payload) {
    return Repository.post(`${GETALLHOMEPAGEDATA}`, payload);
  },
  createNewsletter(payload) {
    return Repository.post(`${CREATE}`, payload, formDataConfig);
  },
  getNewsletter(payload) {
    return Repository.post(`${GETALL}`, payload);
  },
  deleteNewsletter(payload) {
    return Repository.post(`${DELETE}`, payload);
  },
  updateNewsletter(payload) {
    return Repository.post(`${UPDATE}`, payload);
  },
};

const transformRoute = (route = "") => {
  return `/cms/listing/${route}`;
};
