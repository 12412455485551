import Repository from "./Repository";

const REGISTER = "signupWithPassword";
const GENERATE_OTP = "GenerateOTP";
const VERIFYOTP = "VerifyOTP";
const LOGIN = "loginWithPassword";
const LOGOUT = "logout";
const FORGOT_PASSWORD = "forgotPassword";
const UPDATE_USER_DETAILS = "updateUserDetails";
const GET_ALL_USER = "customerManagement";
export default {
  signupWithPassword(payload) {
    return Repository.post(transformRoute(REGISTER), payload);
  },

  generateOTP(payload) {
    return Repository.post(transformRoute(GENERATE_OTP), payload);
  },
  VerifyOTP(payload) {
    return Repository.post(transformRoute(VERIFYOTP), payload);
  },

  loginWithPassword(payload) {
    return Repository.post(transformRoute(LOGIN), payload);
  },
  logout() {
    return Repository.post(transformRoute(LOGOUT));
  },
  forgotPassword(payload) {
    return Repository.post(transformRoute(FORGOT_PASSWORD), payload);
  },
  UpdateUserDetails(payload) {
    return Repository.post(transformRoute(UPDATE_USER_DETAILS), payload);
  },

  customerManagement(payload) {
    return Repository.get(transformRoute(GET_ALL_USER), payload);
  },
};

const transformRoute = (route) => {
  return `/auth/${route}`;
};
