export default {
  pages: [],
  listings: [],
  selectedpagecategory: {},
  pageCategories: [],
  selectedBlog: {},
  filtertags: [],
  filters: [],
  categorywisepages: {},
  careers: [],
  selectedCareer: {},
  blogPages: {},
  podcastPages: {},
  newsletters: {},
  selectedNewsletter: {},
  homePage: {},
  homePageAllData: [],
  // leadership: [],
  boardMember: [],
  youngTalentCouncil: [],
  pastNationalPresidents: [],
  nationalSecretariat: [],
  mentor: [],
  gatt: [],
  specialInvities: [],
  nationalStrategicGroup: [],
  chapterExcellenceGroup: [],
};
