import Repository from "./Repository";

const REGISTER = "signupWithPassword";
const LOGIN = "/auth/loginWithPassword";

export default {
  signupWithPassword(payload) {
    return Repository.post(transformRoute(REGISTER), payload);
  },

  loginWithPassword(payload) {
    return Repository.post(LOGIN, payload);
  }
};

const transformRoute = route => {
  return `/ecommerce/auth/${route}`;
};
