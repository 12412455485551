import RepositoryFactory from "@/apis";
const elearning = RepositoryFactory.elearning;

export default {
  getCategories({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getCategories(payload);
        // console.log(data);
        commit("getCorseCategory", data.data.reverse());
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  createCategories({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.createCategories(payload);
        // console.log(data);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteCategories({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.deleteCategories(payload);

        // console.log(data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editCategories({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let finalpayload = {};
        if (payload.name != "") {
          finalpayload.name = payload.name;
        }
        const { data } = await elearning.editCategories(payload);
        commit("editCategory", payload);
        // console.log(data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  createAuthorDetails({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.createAuthorDetails(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editAuthorDetails({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.editAuthorDetails(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getCourses({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getCourses(payload);
        // console.log(data);
        // console.log(payload);
        commit("getCourses", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getCoursesByCategoryId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getCoursesByCategoryId(payload);
        // console.log(data);
        commit("getCoursesByCategoryId", data.data);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteCourse({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.deleteCourse(payload);

        // console.log(data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createCourse({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.createCourse(payload);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // console.log(data);
  deleteAuthorDetails({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.deleteAuthorDetails(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editCourse({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.editCourse(payload);

        // console.log(data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getAuthorDetails({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getAuthorDetails(payload);
        commit("getAuthorDetails", data.data);
        // console.log(data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getChapter({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getChapter(payload);
        // console.log(data);
        commit("getChapter", data.data);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createChapter({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.createChapter(payload);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteChapter({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.deleteChapter(payload);

        console.log(data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editChapter({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.editChapter(payload);

        // console.log(data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getChapterByCourseId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getChapterByCourseId(payload);
        // console.log(data);
        commit("getChapterByCourseId", data.data);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getTopic({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getTopic(payload);
        // console.log(data);
        commit("getTopic", data.data);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getTopicByChapterId({ commit }, payload) {
    // console.log(payload);
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getTopicByChapterId(payload);
        // console.log(data);
        commit("getTopicByChapterId", data.data);
        // console.log(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createTopic({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.createTopic(payload, {
          onUploadProgress: (progressEvent) => {
            commit(
              "SET_PERCENTAGE",
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        });

        // console.log(payload);
        resolve(data);
        commit("SET_PERCENTAGE", 0);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteTopic({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.deleteTopic(payload);

        // console.log(data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editTopic({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.editTopic(payload, {
          onUploadProgress: (progressEvent) => {
            commit(
              "SET_PERCENTAGE",
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        });

        // console.log(data);
        resolve(data);
        commit("SET_PERCENTAGE", 0);
      } catch (error) {
        reject(error);
      }
    });
  },
  getComments({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getComments(payload);

        commit("SET_COMMENTS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addComment({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.addComment(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addReply({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.addReply(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  like({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.like(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  unlike({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.unlike(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addQuestion({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.addQuestion(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAssessment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getAssessment(payload);
        commit("SET_ASSESSMENT", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteQuestion({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.deleteQuestion(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editQuestion({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.editQuestion(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteAssessment({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.deleteAssessment(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getProfile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.getProfile(payload);
        commit("SET_PROFILE", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editUser({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.editUser(payload);
        commit("SET_PROFILE", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addEmbedded({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.addEmbedded(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteEmbedded({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.deleteEmbedded(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editEmbedded({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await elearning.editEmbedded(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
