// import RepositoryFactory from "@/apis";
import { reject } from "lodash";
import RepositoryFactory from "@/apis";

const category = RepositoryFactory.ecommerce1.category;
const subcategory = RepositoryFactory.ecommerce1.subcategory;
const tax = RepositoryFactory.ecommerce1.Tax;
const coupon = RepositoryFactory.ecommerce1.coupon;
const dispatch = RepositoryFactory.ecommerce1.Dispatch;
const banner = RepositoryFactory.ecommerce1.banner;
const testimonials = RepositoryFactory.ecommerce1.Testimonials;
const gallery = RepositoryFactory.ecommerce1.Gallery;
const news = RepositoryFactory.ecommerce1.News;
const auth = RepositoryFactory.ecommerce1.auth;
const contactUs = RepositoryFactory.ecommerce1.contactUs;
const Transaction = RepositoryFactory.ecommerce1.Transaction;
const newsLetter = RepositoryFactory.ecommerce1.newsLetter;
export default {
  // ################################################################
  // -----------------  CATEGORY -------------------------
  // ################################################################
  getCategoriesNew({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.getCategories();
        commit("UPDATE_CATEGORY_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetSingleCategories({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.getSingleCategories(payload);
        commit("UPDATE_SINGLE_CATEGORY_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  categoryAddCollection({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  categoryDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editCategory({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.editCategory(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // -------------------    SUBCATEGORY  -----------------------------
  // ################################################################
  getSubCategories({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.getSubcategory();
        commit("UPDATE_SUBCATEGORY_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  subCategoryAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  subCategoryEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.editCategory(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  subCategoryDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // ----------------- Testmonials  -------------------------
  // ################################################################
  getTestmonials({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await testimonials.getCollection();
        commit("UPDATE_TESTIMONIAL_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  testmonialsAddCollection({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await testimonials.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  testmonialsDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await testimonials.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  testmonialsEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await testimonials.editCategory(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // ----------------- Gallery  -------------------------
  // ################################################################
  getGallery({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await gallery.getCollection();
        commit("UPDATE_GALLERY_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  galleryAddCollection({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await gallery.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  galleryDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await gallery.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  gallerysEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await gallery.editCategory(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // ----------------- NEWS  -------------------------
  // ################################################################
  getNews({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await news.getCollection();
        commit("UPDATE_NEWS_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  newsAddCollection({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await news.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  newsDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await news.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  newsEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await news.editCategory(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    TAXES  -----------------------------
  // ############################################################
  getTaxes({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await tax.getAllCollections();
        commit("UPDATE_TAXES_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  taxAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await tax.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  taxEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await tax.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  taxDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await tax.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ############################################################
  // -------------------    Coupons  -----------------------------
  // ############################################################
  getCoupon({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.getAllCollection();
        commit("UPDATE_COUPONS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  submitCoupon({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.submitCoupon(payload);
        commit("UPDATE_COUPONS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  couponAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  couponEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  couponDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    DISPATCHERS  -----------------------------
  // ############################################################
  getDispatches({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await dispatch.getAllCollections();
        commit("UPDATE_DISPATCH_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  dispatchAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await dispatch.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  dispatchEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await dispatch.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  dispatchDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await dispatch.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ############################################################
  // -------------------    Banners  -----------------------------
  // ############################################################
  BannerGetCollection({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await banner.getCollection();
        commit("UPDATE_BANNER_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  BannerAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(payload);
        const { data } = await banner.addCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  BannerEdiCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await banner.ediCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  BannerDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await banner.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    Get USER  -----------------------------
  // ############################################################
  customerManagement({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.customerManagement();
        commit("ADMIN_ALL_USERS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // GetUser({}, payload) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //         const {data} = await address.deleteOneCollection(payload);
  //         resolve(data);
  //       } catch (error) {
  //         reject(error);
  //       }
  // });
  // }
  // ############################################################
  // -------------------   CONTACT With us  --------------------
  // ############################################################
  contactUsGetCollection({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await contactUs.getCollection();
        commit("CONTACT_US_STATE", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  contactUsAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await contactUs.addCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // ---------------  Razorpay transaction record  -------------
  // ############################################################
  addOneCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Transaction.addOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateOneCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Transaction.UpdateOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }, // ############################################################
  // -------------------   NEWSLETTER  ---------------------------
  // ############################################################
  newsLetterGetCollection({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await newsLetter.getCollection(payload);
        commit("NEWSLETTER_STATE", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  newsLetterAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await newsLetter.addCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
