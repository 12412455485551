import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const GET_CATEGORY = "category/";
const CREATE_CATEGORY = "category/create";
const EDIT_CATEGORY = "category/edit";
const DELETE_CATEGORY = "category/delete";
const GET_COURSE = "course/";
const GET_COURSES_BY_CATEGORY_ID = "course/getCourseByCategoryId";
const CREATE_COURSE = "course/create";
const EDIT_COURSE = "course/edit";
const DELETE_COURSE = "course/delete";
const GET_AUTHOR = "author/";
const CREATE_AUTHOR = "author/create";
const EDIT_AUTHOR = "author/edit";
const DELETE_AUTHOR = "author/delete";
const GET_CHAPTER = "chapter/";
const CREATE_CHAPTER = "chapter/create";
const DELETE_CHAPTER = "chapter/delete";
const EDIT_CHAPTER = "chapter/edit";
const GET_CHAPTER_BY_COURSE_ID = "chapter/getChapterByCourseId";
const CREATE_TOPIC = "topic/create";
const DELETE_TOPIC = "topic/delete";
const GET_TOPIC = "topic/";
const GET_TOPIC_BY_Chapter_ID = "topic/getTopicByChapterId";
const EDIT_TOPIC = "topic/edit";
const GET_COMMENTS = "course/getSingle";
const ADD_COMMENT = "course/addComment";
const ADD_REPLY = "course/addReply";
const ADD_LIKE = "course/like";
const REMOVE_LIKE = "course/unlike";
const ADD_QUESTION = "question/create";
const GET_PROFILE = "caregiver/profile/getProfileByUserId";
const EDIT_USER = "caregiver/profile/edit";
const DELETE_USER = "caregiver/profile/delete";
const GET_ASSESSMENT = "question/getQuestionByChapterId";
const DELETE_QUESTION = "question/delete";
const EDIT_QUESTION = "question/edit";
const DELETE_ASSESSMENT = "question/deleteMany";
const ADD_EMBEDDED = "embedded/create";
const DELETE_EMBEDDED = "embedded/delete";
const EDIT_EMBEDDED = "embedded/edit";
export default {
  getCategories(payload) {
    // console.log(payload);
    if (!payload) {
      return Repository.get(transformRoute(GET_CATEGORY), payload);
    }
    if (payload.limit) {
      const a = payload.limit;
      const limit = "?limit=";
      const GET_CATEGORY_LIMIT = GET_CATEGORY + limit + a;
      return Repository.get(transformRoute(GET_CATEGORY_LIMIT), payload);
    }
    if (payload.searchCat) {
      const GET_CATEGORY_SEARCH = GET_CATEGORY + "?search=" + payload.searchCat;
      return Repository.get(transformRoute(GET_CATEGORY_SEARCH), payload);
    }
  },

  createCategories(payload) {
    return Repository.post(
      transformRoute(CREATE_CATEGORY),
      payload,
      formDataConfig
    );
  },
  editCategories(payload) {
    return Repository.post(transformRoute(EDIT_CATEGORY), payload);
  },
  deleteCategories(payload) {
    return Repository.post(transformRoute(DELETE_CATEGORY), payload);
  },
  getCourses(payload) {
    if (payload) {
      // console.log("course payload", payload);
      // const limit = payload.limit;
      // if (limit) {
      // const NEW_ROUTE = GET_COURSE + "?limit=" + limit;
      //   return Repository.get(transformRoute(NEW_ROUTE), payload);
      // } else if (payload.category || payload.language || payload.level) {
      //   // console.log(payload.language.length);
      //   let NEW_ROUTE = GET_COURSE + "?";
      //   const cat_length = payload.category.length;
      //   const level_length = payload.level.length;
      //   const lang_length = payload.language.length;
      //   const price_length = payload.price.length;
      //   if (cat_length) {
      //     for (let i = 0; i <= cat_length - 1; i++) {
      //       const category = payload.category[i];
      //       NEW_ROUTE += "category=" + category + "&";
      //     }
      //   }
      //   if (level_length) {
      //     for (let i = 0; i <= level_length - 1; i++) {
      //       const level = payload.level[i];
      //       NEW_ROUTE += "level=" + level + "&";
      //     }
      //   }
      //   if (lang_length) {
      //     for (let i = 0; i <= lang_length - 1; i++) {
      //       const lang = payload.language[i];
      //       NEW_ROUTE += "language=" + lang + "&";
      //     }
      //   }
      //   if (price_length) {
      //     const price = payload.price;
      //     // NEW_ROUTE += "price=" + price + "&";
      //     // console.log(NEW_ROUTE);
      //   }
      //   // console.log(NEW_ROUTE);
      return Repository.post(transformRoute(GET_COURSE), payload);
      // }
    } else {
      return Repository.post(transformRoute(GET_COURSE), payload);
    }
  },
  getCoursesByCategoryId(payload) {
    return Repository.post(transformRoute(GET_COURSES_BY_CATEGORY_ID), payload);
  },
  createCourse(payload) {
    return Repository.post(
      transformRoute(CREATE_COURSE),
      payload,
      formDataConfig
    );
  },
  editCourse(payload) {
    return Repository.post(transformRoute(EDIT_COURSE), payload);
  },
  deleteCourse(payload) {
    return Repository.post(transformRoute(DELETE_COURSE), payload);
  },
  getAuthorDetails(payload) {
    return Repository.get(transformRoute(GET_AUTHOR), payload);
  },
  createAuthorDetails(payload) {
    return Repository.post(transformRoute(CREATE_AUTHOR), payload);
  },
  editAuthorDetails() {
    return Repository.post(transformRoute(EDIT_AUTHOR));
  },
  deleteAuthorDetails() {
    return Repository.post(transformRoute(DELETE_AUTHOR));
  },
  getChapter(payload) {
    return Repository.get(transformRoute(GET_CHAPTER), payload);
  },
  createChapter(payload) {
    return Repository.post(transformRoute(CREATE_CHAPTER), payload);
  },
  deleteChapter(payload) {
    return Repository.post(transformRoute(DELETE_CHAPTER), payload);
  },
  getChapterByCourseId(payload) {
    return Repository.post(transformRoute(GET_CHAPTER_BY_COURSE_ID), payload);
  },
  editChapter(payload) {
    return Repository.post(transformRoute(EDIT_CHAPTER), payload);
  },
  // getChapterByCourseId(payload) {
  //   return Repository.post(transformRoute(GET_COURSES_BY_CATEGORY_ID), payload);
  // },
  getTopic(payload) {
    return Repository.get(transformRoute(GET_TOPIC), payload);
  },
  getTopicByChapterId(payload) {
    return Repository.post(transformRoute(GET_TOPIC_BY_Chapter_ID), payload);
  },

  createTopic(payload, config) {
    return Repository.post(transformRoute(CREATE_TOPIC), payload, config);
  },
  deleteTopic(payload) {
    return Repository.post(transformRoute(DELETE_TOPIC), payload);
  },
  editTopic(payload, config) {
    return Repository.post(transformRoute(EDIT_TOPIC), payload, config);
  },
  getComments(payload) {
    const NEW_ROUTE = GET_COMMENTS + "?_id=" + payload._id;
    return Repository.get(transformRoute(NEW_ROUTE), payload);
  },
  addComment(payload) {
    return Repository.post(transformRoute(ADD_COMMENT), payload);
  },
  addReply(payload) {
    return Repository.post(transformRoute(ADD_REPLY), payload);
  },
  like(payload) {
    return Repository.post(transformRoute(ADD_LIKE), payload);
  },
  unlike(payload) {
    return Repository.post(transformRoute(REMOVE_LIKE), payload);
  },
  addQuestion(payload) {
    return Repository.post(transformRoute(ADD_QUESTION), payload);
  },
  getProfile(payload) {
    return Repository.post(transformRoute(GET_PROFILE), payload);
  },
  editUser(payload) {
    return Repository.post(transformRoute(EDIT_USER), payload);
  },
  deleteUser(payload) {
    return Repository.post(transformRoute(DELETE_USER), payload);
  },
  getAssessment(payload) {
    return Repository.post(transformRoute(GET_ASSESSMENT), payload);
  },
  deleteQuestion(payload) {
    return Repository.post(transformRoute(DELETE_QUESTION), payload);
  },
  editQuestion(payload) {
    return Repository.post(transformRoute(EDIT_QUESTION), payload);
  },
  deleteAssessment(payload) {
    return Repository.post(transformRoute(DELETE_ASSESSMENT), payload);
  },
  addEmbedded(payload) {
    return Repository.post(transformRoute(ADD_EMBEDDED), payload);
  },
  deleteEmbedded(payload) {
    return Repository.post(transformRoute(DELETE_EMBEDDED), payload);
  },
  editEmbedded(payload) {
    return Repository.post(transformRoute(EDIT_EMBEDDED), payload);
  },
};

const transformRoute = (route) => {
  return `/elearning/${route}`;
};
