import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GET = "getcategory";

export default {
  GetCategory(payload) {
    return Repository.get(transformRoute(GET), payload);
  },
};

const transformRoute = (route) => {
  return `/category/${route}`;
};
