import RepositoryFactory from "@/apis";
import { Decrypt } from "@/helpers/CryptoJS";
const cart = RepositoryFactory.ecommerce1.cart;
const address = RepositoryFactory.ecommerce1.Address;
const order = RepositoryFactory.ecommerce1.order;
const userAuth = RepositoryFactory.ecommerce1.UserAuth;
const wishlist = RepositoryFactory.ecommerce1.Wishlist;
const orderReturns = RepositoryFactory.ecommerce1.OrderReturn;
export default {
  // ############################################################
  // -------------------    CARTT -----------------------------
  // ############################################################
  getCart({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.getAllCollections();
        commit("UPDATE_CARTS_INFO", data.data);
        resolve(data);
      } catch (error) {
        commit("UPDATE_CARTS_INFO", []);
        reject(error);
      }
    });
  },
  cartAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  cartEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  cartDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  cartDeleteAll({}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.deleteAll();
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    Address  -----------------------------
  // ############################################################
  getAddresses({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await address.getAllCollection();
        commit("UPDATE_ADDRESS_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getOneAddressesByUser({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await address.getOneCollectionByUser();
        commit("UPDATE_ADDRESS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  AddressAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await address.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  AddressEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await address.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  AddressDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await address.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }, // ############################################################
  // -------------------    ORDERSS -----------------------------
  // ############################################################
  getOrders({ commit }, payload = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.getAllCollections(payload);
        commit("UPDATE_ORDERS_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleOrders({ commit }, payload = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.getOneCollection(payload);
        commit("UPDATE_SInGLEORDERS_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  orderAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  orderEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  orderDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteDispatchFromOneCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.deleteDispatchFromOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getRazorPayCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.getRazorPayCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    USERS -----------------------------
  // ############################################################
  getCurrentUser({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await userAuth.getUserDetails();
        commit("UPDATE_USER_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // getCurrentUser({ commit }) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await userAuth.getUserDetails();
  //       commit("UPDATE_USER_INFO", data.data);
  //       resolve(data);
  //     } catch (error) {
  //       if (
  //         error.response.data.message.includes(
  //           "No authorization token was found"
  //         ) &&
  //         Object.keys(store.state.authNew.user).length > 0
  //       )
  //         await commit("logout", null, { root: true });
  //       reject(error);
  //     }
  //   });
  // },
  UpdateUserDetails({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.UpdateUserDetails(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    WISHLIST -----------------------------
  // ############################################################
  getWishlist({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await wishlist.getAllCollections();
        commit("UPDATE_WISHLIST_INFO", data);
        resolve(data);
      } catch (error) {
        commit("UPDATE_CARTS_INFO", []);
        reject(error);
      }
    });
  },
  wishlistAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await wishlist.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  MoveToCartFromWishlist({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await wishlist.MoveToCartFromWishlist(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  wishlistEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await wishlist.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  wishlistDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await wishlist.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    ORDER RETURNS -----------------------------
  // ############################################################
  getOrderReturns({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orderReturns.getAllCollections();
        commit("UPDATE_ORDER_RETURNS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleCollections({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orderReturns.getSingleCollections(payload);
        commit("UPDATE_ORDER_RETURNS_SINGLE_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  OrderReturnsAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orderReturns.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  OrderReturnsEditOneCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orderReturns.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
