import { Decrypt, Encript } from "@/helpers/CryptoJS";
import axios from "axios";

// You can use your own logic to set your local or production domain

const baseDomain = process.env.VUE_APP_BASE_URL;
const baseURL = `${baseDomain}`;

const userInfoLocalStorage = localStorage.getItem("token");

const API = axios.create({
  baseURL: baseURL,
  headers: {
    "project-suite": "Elearning",
    "community-name": process.env.VUE_APP_COMMUNITY_NAME,
  },
});

async function convertToBase64(file) {
  return new Promise(async (resolve, reject) => {
    let reader = new FileReader();
    await reader.readAsDataURL(file);

    reader.onloadend = await function () {
      resolve(reader.result);
    };
  });
}

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    let configData = config;
    if (configData) {
      if (
        config.headers["Content-Type"] === "multipart/form-data" &&
        parseInt(process.env.VUE_APP_ENCRYPTION) == 1
      ) {
        let mediaList = [];
        for (const key in config.data) {
          if (config.data[key] instanceof File) {
            mediaList.push(key);
            let data = config.data[key];
            // let base64data = btoa(data);
            let base64data = await convertToBase64(data);
            config.data[key] = base64data;
          }
        }

        config.headers["Content-Type"] = "application/json";
        Object.assign(config.data, { mediaList });
        config.data = {
          data: Encript(JSON.stringify(config.data)),
        };
        if (token) {
          config.headers.authorization = `Bearer ${token}`;
        }
        return config;
      }
      if (
        config.data &&
        config.headers["Content-Type"] !== "multipart/form-data" &&
        parseInt(process.env.VUE_APP_ENCRYPTION) == 1
      ) {
        config.data = {
          // data: config.data,
          data: Encript(JSON.stringify(config.data)),
        };
      }
      if (config.params && parseInt(process.env.VUE_APP_ENCRYPTION) == 1) {
        config.params = {
          params: Encript(JSON.stringify(config.params)),
        };
      }
    }

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => {
    if (response) {
      if (Object.keys(response.data.data).length) {
        response.data.data = Decrypt(response.data.data);
      }
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default API;
