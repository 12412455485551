import Repository from "../Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";

export default {
  getAllVariants(payload) {
    return Repository.get(transformRoute(GET_ENTRY), payload);
  },
  addDiamond(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editDiamond(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteDiamond(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/diamond/${route}`;
};
