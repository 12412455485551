import RepositoryFactory from "@/apis";
const auth = RepositoryFactory.ecommerce1.auth;
const ecommerce = RepositoryFactory.ecommerce1.ecommerce;
const userAuth = RepositoryFactory.ecommerce1.UserAuth;
const orderReturns = RepositoryFactory.ecommerce1.OrderReturn;
export default {
  // ############################################################
  // -------------------    ORDERS -----------------------------
  // ############################################################
  getOrderReturns({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orderReturns.getAllCollections();
        commit("UPDATE_ORDER_RETURNS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleCollections({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orderReturns.getSingleCollections(payload);
        commit("UPDATE_ORDER_RETURNS_SINGLE_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getMediaImageCollections({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orderReturns.getMediaImageCollections(payload);
        commit("MEDIA_ORDER_RETURN_MEDIA_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getMediaVideoCollections({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orderReturns.getMediaVideoCollections(payload);
        commit("MEDIA_ORDER_RETURN_MEDIA_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  OrderReturnsAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orderReturns.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getRejectedReasonCollections({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orderReturns.getRejectedReasonCollections(
          payload
        );
        commit("ANY_ORDER_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
