export default {
  UPDATE_CARTS_INFO(state, payload) {
    state.cartState = payload;
  },
  UPDATE_ADDRESS_INFO(state, payload) {
    state.addressState = payload;
  },
  UPDATE_ORDERS_INFO(state, payload) {
    state.orderState = payload;
  },
  UPDATE_SInGLEORDERS_INFO(state, payload) {
    state.singleOrderState = payload;
  },
  UPDATE_ORDER_RETURNS_INFO(state, payload) {
    state.orderReturnState = payload;
  },
  UPDATE_ORDER_RETURNS_SINGLE_INFO(state, payload) {
    state.orderSingleReturnState = payload;
  },
  UPDATE_DELIVERYDETAILS_INFO(state, payload) {
    state.deliveryDetailsState = payload;
  },
  USER_DETAILS_OF_CART_UPDATED(state, payload) {
    console.log("deliveryDetailsState", payload);
    state.deliveryDetailsState.firstname = payload.firstname;
    state.deliveryDetailsState.lastname = payload.lastname || "";
    state.deliveryDetailsState.email = payload.email;
    state.deliveryDetailsState.mobile = payload.mobile;
  },
  GENERATE_FULL_NAME(state) {
    state.deliveryDetailsState.fullName = "";
    state.deliveryDetailsState.fullName = `${
      state.deliveryDetailsState.firstname || ""
    }  ${state.deliveryDetailsState.lastname || ""}  `;
    console.log(
      "state.deliveryDetailsState.fullName",
      state.deliveryDetailsState.fullName
    );
  },
  UPDATE_DELIVERYDETAILS_USER_ADDRESSS(state, payload) {
    console.log("payload", payload);
    state.deliveryDetailsState.addressId = payload.addressId;
    state.deliveryDetailsState.address1 = payload.address1;
    state.deliveryDetailsState.landMark = payload.landMark;
    state.deliveryDetailsState.townCity = payload.townCity;
    state.deliveryDetailsState.State = payload.State;
  },
  UPDATE_USER_INFO(state, payload) {
    state.userInfoState = payload;
  },
  UPDATE_WISHLIST_INFO(state, payload) {
    state.wishlistState = payload;
  },
  IS_HANDLE_POP_UP(state, payload) {
    state.IshandlePopUp = payload;
  },
  IS_HANDLE_WISHLIST(state, payload) {
    state.IshandleWishListPopUp = payload;
  },
  IS_HANDLE_LOGIN_POP_UP(state, payload) {
    state.ISHandleLoginPopUP = payload;
  },
  IS_HANDLE_REGISTER_POP_UP(state, payload) {
    state.ISHandleRegisterPopUP = payload;
  },
  IS_HANDLE_FORGOT_PASSWORD_POP_UP(state, payload) {
    state.ISHandleForgotPasswordPopUP = payload;
  },
  IS_HANDLE_UNIVERSAL_POPUP(state, payload) {
    console.log(payload, "isShowAddBannerisShowAddBanner");
    state.Universalpopup = payload;
  },
  HANDLE_BLOG(state, payload) {
    console.log(payload, "payload");
    state.userBlog = payload;
  },
};
