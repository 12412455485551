import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GETCATEGORY = "gettag";

export default {
  //   AddPage(payload) {
  //     getsingle;
  //     return Repository.post(transformRoute(ADDPAGE), payload);
  //   },

  GetCategory(payload) {
    return Repository.get(transformRoute(GETCATEGORY), payload);
  },
};

const transformRoute = (route) => {
  return `/ptag/${route}`;
};
