import RepositoryFactory from "@/apis";
const organisation = RepositoryFactory.organisation;

export default {
  addOrganisation({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await organisation.addOrganisation(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addJobOrganisation({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await organisation.addJobOrganisation(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  registerAddOrganisation({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await organisation.registerAddOrganisation(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
