import Repository from "../Repository";

const ADD_ENTRY = "subscribeToNewsLetter";
// const DELETE_ENTRY = "delete";
// const UPDATE_ENTRY = "update";
const GET_ENTRY = "GetNewsLetterInfo";

export default {
  getCollection(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
  addCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  //   updateCollection(payload) {
  //     return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  //   },
  //   deleteCollection(payload) {
  //     return Repository.post(transformRoute(DELETE_ENTRY), payload);
  //   },
};

const transformRoute = (route) => {
  return `ecommerce_ny/connectUs/${route}`;
};
