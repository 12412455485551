import RepositoryFactory from "@/apis";
const membership = RepositoryFactory.membership;

export default {
  verification({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await membership.verfication(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addMembership({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await membership.addMembership(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editMembership({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await membership.editMembership(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllMemberships({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await membership.getAllMemberships(payload);
        commit("SET_ALL_MEMBERSHIPS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleMemberships({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await membership.getSingleMemberships(payload);
        commit("GET_SINGLE_MEMBERSHIPS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  verifyMembership({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await membership.verifyMembership(payload);
        commit("SET_SELECTED_MEMBERSHIP", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addBulkUser({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await membership.addBulkUser(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  email({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await membership.email(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllRequest({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await membership.getAllRequest(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  update({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await membership.update(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
