export default {
  visibleMembers: (state) => {
    let data = state.selectedChannelMembers.filter((x) => x._user != null);
    return data.length && data.slice(0, 6);
  },
  currentUserChannel: (state) => {
    return state.channels.filter((data) => data.isMember);
    // return state.channelInCategory.filter((data) => data.isMember);
  },

  nonMemberChannels: (state) => {
    // return state.channels.filter((data) => !data.isMember);
    // console.log(state.channels);
    return state.channels;
  },
};
