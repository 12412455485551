import modulecategory from "./Category";
import moduleproduct from "./product";
import moduleAuthNew from "./Auth1";
import moduleUser from "./User/index";
import moduleAdmin from "./Admin/index";
import ModuleOrder from "./Orders/index";
import ModuleComponent from "./Component/index";

export default {
  category: modulecategory,
  product: moduleproduct,
  auth: moduleAuthNew,
  euser: moduleUser,
  eadmin: moduleAdmin,
  order: ModuleOrder,
  component: ModuleComponent,
};
