import Vue from "vue";

export default {
  getCorseCategory(state, payload) {
    state.categories = payload;
  },
  getCourses(state, payload) {
    state.course = payload;
    // console.log(payload);
  },
  selectedCategory(state, payload) {
    state.selectedCategory = payload;
  },
  getCoursesByCategoryId(state, payload) {
    state.coursesByCategoryId = payload;
  },
  // editCategory(state, payload) {
  //   state.selectedData = payload;
  // }
  getAuthorDetails(state, payload) {
    state.author = payload;
  },
  getChapter(state, payload) {
    state.chapter = payload;
  },
  selectedCourseHome(state, payload) {
    state.selectedCourseHome = payload;
  },
  selectedWishlist(state, payload) {
    state.wishlist = payload;
    // console.log(payload);
  },
  selectedCategoryForEdit(state, payload) {
    state.selectedCategoryEdit = payload;
    // console.log(payload);
  },
  editCategory(state, payload) {
    // console.log(payload);
  },
  selectedCourse(state, payload) {
    state.selectedCourseHome = payload;
  },
  getChapterByCourseId(state, payload) {
    state.chapterByCourseId = payload;
  },
  getTopic(state, payload) {
    state.topic = payload;
  },
  getTopicByChapterId(state, payload) {
    state.topicByChapterId = payload;
  },
  getChapterByCourseId(state, payload) {
    state.chapterByCourseId = payload;
  },
  selectedCourse(state, payload) {
    state.selectedCourse = payload;
  },
  cart(state, payload) {
    state.cart = payload;
  },
  filterData(state, payload) {
    state.filterData = payload;
  },
  selectedCat(state, payload) {
    state.selectedCat = payload;
  },
  duration(state, payload) {
    state.videoDuration = payload;
  },
  SET_SELECTED_TOPIC(state, payload) {
    state.selectedTopics = payload;
  },
  SET_COMMENTS(state, payload) {
    state.comments = payload;
  },
  SET_ASSESSMENT(state, payload) {
    Vue.set(state, "assessments", payload);
    // state.assessments = payload;
  },
  SET_CERTIFICATE_COURSE(state, payload) {
    console.log(payload);
    state.certificateCourse = payload;
  },
  SET_PERCENTAGE(state, payload) {
    state.progressPercentage = payload;
  },
  SET_SEARCH(state, payload) {
    state.searchCourse = payload;
  },
  SET_PROFILE(state, payload) {
    state.profileData = payload;
  },
};
