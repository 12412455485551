import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const ADD = "addhome";
const GET = "gethome";

export default {
  AddBanner(payload) {
    return Repository.post(transformRoute(ADD), payload, formDataConfig);
  },
  GetBanner(payload) {
    return Repository.get(transformRoute(GET), payload);
  },
};

const transformRoute = (route) => {
  return `/home/${route}`;
};
