import RepositoryFactory from "@/apis";
const job = RepositoryFactory.job;

export default {
  getAllJobs({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let payload2 = { ...payload };
        const myuserid = rootState.auth.user._id;
        if (rootState.auth.user.membershiptype == "Recruiter/Institution") {
          // Object.assign(payload2, { userid: myuserid });
        } else {
          Object.assign(payload2, { myuserid: myuserid });
        }
        const { data } = await job.getAllJobs(payload2);
        commit("SET_JOBS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllAdminJobs({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await job.getAllAdminJobs(payload);
        commit("SET_JOBS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleJob({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await job.getSingleJob(payload);
        commit("SET_SINGLEJOB", data.data[0]);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  jobCreate({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // const myuserid = rootState.auth.user._id;
        // console.log("job", myuserid);
        // const myorganisation = rootState.auth.user.organisation._id;
        // console.log("job", myorganisation);
        // let payload2 = new FormData();
        // Object.keys(payload).map((ele) => {
        //   payload2.append(ele, payload[ele]);
        // });
        // payload2.append("userid", myuserid);
        // payload2.append("organisation", myorganisation);
        // console.log("job", payload);
        const { data } = await job.jobCreate(payload);
        // commit("SET_JOBS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  jobUpdate({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await job.jobUpdate(payload);
        // commit("SET_SINGLEJOB", data.data[0]);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateAdminJobs({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await job.UpdateAdminJobs(payload);
        // commit("SET_SINGLEJOB", data.data[0]);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  jobrequest({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const myuserid = rootState.auth.user._id;
        let payload2;
        if (payload.video) {
          payload2 = new FormData();
          Object.keys(payload).map((ele) => {
            payload2.append(ele, payload[ele]);
          });
          if (!payload.userid) {
            payload2.append("userid", myuserid);
          }
        } else {
          payload2 = { ...payload };

          if (!payload.userid) {
            Object.assign(payload2, { userid: myuserid });
          }
        }

        const { data } = await job.jobrequest(payload2);
        // commit("SET_JOBS", data.data);

        resolve(myuserid);
      } catch (error) {
        reject(error);
      }
    });
  },
  getrequest({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const myuserid = rootState.auth.user._id;
        let result;
        if (payload.jobid) {
          const { data } = await job.getrequest(payload);
          result = data;
        } else {
          const { data } = await job.getrequest({
            userid: myuserid,
            ...payload,
          });
          result = data;
        }

        if (payload.status == "applied") {
          commit("SET_APPLIED_JOB", result.data);
        }
        if (
          payload.status == "accepted" &&
          payload.whoWillAccept == "applicant"
        ) {
          commit("SET_OFFER_JOB", result.data);
        }
        if (payload.jobid) {
          commit("SET_REQUEST_JOB", result.data);
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllAdminRequests({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const myuserid = rootState.auth.user._id;
        let result;
        if (payload.jobid) {
          const { data } = await job.getAllAdminRequests(payload);
          result = data;
        } else {
          const { data } = await job.getAllAdminRequests({
            userid: myuserid,
            ...payload,
          });
          result = data;
        }

        // if (payload.status == "applied") {
        //   commit("SET_APPLIED_JOB", result.data);
        // }
        // if (
        //   payload.status == "accepted" &&
        //   payload.whoWillAccept == "applicant"
        // ) {
        //   commit("SET_OFFER_JOB", result.data);
        // }
        // if (payload.jobid) {
        //   commit("SET_REQUEST_JOB", result.data);
        // }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },
  getsinglerequest({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await job.getsinglerequest(payload);
        // commit("SET_SINGLEJOB", data.data[0]);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getIndustry({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await job.getIndustry(payload);
        // commit("SET_SINGLEJOB", data.data[0]);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSubIndustry({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await job.getSubIndustry(payload);
        // commit("SET_SINGLEJOB", data.data[0]);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getrecommended({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await job.getrecommended(payload);
        // commit("SET_SINGLEJOB", data.data[0]);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
