import { Encript, Decrypt } from "@/helpers/CryptoJS";
import axios from "axios";

// You can use your own logic to set your local or production domain

const baseDomain = process.env.VUE_APP_BASE_URL;
const baseURL = `${baseDomain}`;

const userInfoLocalStorage = localStorage.getItem("token");

const API = axios.create({
  baseURL: baseURL,
  headers: {
    "project-suite": "Elearning",
    "community-name": process.env.VUE_APP_COMMUNITY_NAME,
  },
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (config.data && parseInt(process.env.VUE_APP_ENCRYPTION) !== 0) {
      config.data = {
        data: Encript(JSON.stringify(config.data)),
      };

      // config.data = {
      //   data: Encript(JSON.stringify(Object.fromEntries(config.data))),
      // };
    } else {
      config.data;
    }
    if (config.params && parseInt(process.env.VUE_APP_ENCRYPTION) !== 0) {
      config.params = {
        params: Encript(JSON.stringify(config.params)),
      };
    } else {
      config.params;
    }

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => {
    if (response) {
      if (Object.keys(response.data.data).length) {
        response.data.data = Decrypt(response.data.data);
      }
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default API;
