import Repository from "@/apis/Repository.js";

const GETTAGSBYCATEGORY = "gettagsbycategory";
const GETFILTERBYCATEGORY = "getfilterbycategory";
const GETTAGSBYFILTER = "gettagsbyfilter";
export default {
  getTagsbyCategory(payload) {
    return Repository.get(transformRoute(GETTAGSBYCATEGORY), {
      params: payload,
    });
  },
  GetTagsbyFilter(payload) {
    return Repository.get(transformRoute(GETTAGSBYFILTER), {
      params: payload,
    });
  },
  getfilterbycategory(payload) {
    return Repository.get(transformRoute(GETFILTERBYCATEGORY), {
      params: payload,
    });
  },
};

const transformRoute = (route = "") => {
  return `/cms/filter/${route}`;
};
