import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const GETALL = "getall";
const getAllAdminJobs = "getAllAdminJobs";
const GETSINGLE = "getsingle";
const jobCreate = "create";
const jobUpdate = "update";
const UpdateAdminJobs = "UpdateAdminJobs";
const jobrequest = "request";
const getrequest = "getrequest";
const getAllAdminRequests = "GetAdminRequest";
const getsinglerequest = "getsinglerequest";
const getIndustry = "nhrdn/master/getindustry";
const getSubIndustry = "nhrdn/master/getsubindustry";
const getrecommended = "recommended";

export default {
  getAllJobs(payload) {
    return Repository.post(transformRoute(GETALL), payload);
  },
  getAllAdminJobs(payload) {
    return Repository.post(transformRoute(getAllAdminJobs), payload);
  },
  getSingleJob(payload) {
    return Repository.post(transformRoute(GETSINGLE), payload);
  },
  async jobCreate(payload) {
    // const res = await Fetch_Repository(transformRoute(jobCreate), payload);
    // return res;
    return Repository.post(transformRoute(jobCreate), payload);
  },
  async jobUpdate(payload) {
    // const res = await Fetch_Repository(transformRoute(jobUpdate), payload);
    // return res;
    return Repository.post(transformRoute(jobUpdate), payload);
  },
  async UpdateAdminJobs(payload) {
    // const res = await Fetch_Repository(transformRoute(jobUpdate), payload);
    // return res;
    return Repository.post(transformRoute(UpdateAdminJobs), payload);
  },
  jobrequest(payload) {
    return Repository.post(transformRoute(jobrequest), payload, formDataConfig);
  },
  getrequest(payload) {
    return Repository.post(transformRoute(getrequest), payload);
  },
  getAllAdminRequests(payload) {
    return Repository.post(transformRoute(getAllAdminRequests), payload);
  },
  getsinglerequest(payload) {
    return Repository.post(transformRoute(getsinglerequest), payload);
  },
  getIndustry(payload) {
    return Repository.post(`${getIndustry}`, payload);
  },
  getSubIndustry(payload) {
    return Repository.post(`${getSubIndustry}`, payload);
  },
  getrecommended(payload) {
    return Repository.post(transformRoute(getrecommended), payload);
  },
};

const transformRoute = (route) => {
  return `/nhrdn/job/${route}`;
};
