export default {
  cartState: [],
  addressState: [],
  orderState: [],
  singleOrderState: [],
  orderReturnState: [],
  orderSingleReturnState: [],
  userInfoState: [],
  wishlistState: [],
  IshandlePopUp: false,
  IshandleWishListPopUp: false,
  ISHandleLoginPopUP: false,
  ISHandleRegisterPopUP: false,
  ISHandleForgotPasswordPopUP: false,
  userBlog: {},
  deliveryDetailsState: {},
  Universalpopup: false,
};
