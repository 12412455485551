import Repository from "../Repository";
const GET_ENTRY = "getUser";

export default {
  getUserDetails() {
    return Repository.get(transformRoute(GET_ENTRY));
  },
};

const transformRoute = (route) => {
  return `/auth/${route}`;
};
