import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const student = "student";
const deleteStudent = "student/delete";
const GET_STUDENT_BY_USERID = "student/getStudentByUserId";
const EDIT_STUDENT = "student/edit";
const GET_SINGLE_STUDENT = "student/";
export default {
  getStudents(payload) {
    return Repository.get(transformRoute(student), payload);
  },
  deleteStudent(payload) {
    return Repository.post(transformRoute(deleteStudent), payload);
  },
  editStudents(payload) {
    return Repository.post(transformRoute(EDIT_STUDENT), payload);
  },
  getStudentByUserId(payload) {
    return Repository.post(transformRoute(GET_STUDENT_BY_USERID), payload);
  },
  getsinglestudent(payload) {
    return Repository.get(transformRoute(GET_SINGLE_STUDENT + payload));
  },
};

const transformRoute = (route) => {
  return `/elearning/${route}`;
};
