import Vue from "vue";
export default {
  RESET_CREATE_POST(state) {
    state.type = "";
    state.media = null;
    state.text = "";
    state.url = "";
    state.Answers = "";
    state.selectedChannel = null;
  },
  SET_ALL_POSTS(state, payload) {
    let data = payload.filter((x) => x._user);
    state.posts = data;
  },

  SET_POST(state, payload) {
    state[payload.key] = payload.value;
  },
  SET_URL(state, payload) {
    state[payload.key] = payload.value;
  },
  SET_POSTING_CHANNELS(state, payload) {
    state.postingChannels = payload;
  },
  SET_CURRENT_PAGE(state, payload) {
    state.previousPage = state.currentPage;
    state.currentPage = payload;
  },
  LOAD_POST_COMMENT(state, payload) {
    if (state.postComments[payload._id]) {
      Vue.set(state.postComments[payload._id], payload._id, payload.comments);
    } else {
      Vue.set(state.postComments, payload._id, payload.comments);
    }
  },
  LOAD_COMMENT_REPLY(state, payload) {
    state.commentReplies[payload._id] = payload.replies;
  },
  ADD_POST_COMMENT(state, payload) {
    if (state.postComments[payload.parentId]) {
      // let newPayload;
      // if (payload._user._id) {
      //   newPayload = {
      //     ...payload,
      //     _user: { ...payload._user, id: payload._user._id },
      //   };
      // } else {
      //   newPayload = payload;
      // }
      Vue.set(state.postComments, payload.parentId, [
        payload,
        ...state.postComments[payload.parentId],
      ]);
      let postIndex = state.posts.findIndex(
        (post) => post._id == payload.parentId
      );
      Vue.set(
        state.posts[postIndex],
        "totalComments",
        state.posts[postIndex].totalComments + 1
      );
      // Vue.set(state.currentuserpostComments, payload.parentId, [
      //   ...state.currentuserpostComments[payload.parentId],
      //   newPayload,
      // ]);
    }
  },
  ADD_COMMENT_REPLY(state, payload) {
    // let newPayload;
    // if (payload._user._id) {
    //   newPayload = {
    //     ...payload,
    //     _user: { ...payload._user, id: payload._user._id },
    //   };
    // } else {
    //   newPayload = payload;
    // }
    if (state.commentReplies[payload.parentId]) {
      Vue.set(state.commentReplies, payload.parentId, [
        payload,
        ...state.commentReplies[payload.parentId],
      ]);

      if (state.currentusercommentReplies[payload.parentId]) {
        Vue.set(state.currentusercommentReplies, payload.parentId, [
          payload,
          ...state.currentusercommentReplies[payload.parentId],
        ]);
      }
    } else {
      state.commentReplies[payload.parentId] = [payload];
      state.currentusercommentReplies[payload.parentId] = [payload];
    }
  },
  COUNT_INCREMENT_COMMENT(state, payload) {
    const commentIndex = state.postComments[payload.postId].findIndex(
      (x) => x._id === payload.commentId
    );
    state.postComments[payload.postId][commentIndex].totalReplies += 1;
  },
  LIKE_POST(state, payload) {
    state.posts = state.posts.map((post) => {
      if (post._id == payload._id) {
        return {
          ...post,
          totalLikes: payload.totalLikes,
          like: payload.totalLikes,
          isLiked: payload.isLiked,
        };
      }
      return post;
    });

    state.currentuserpost = state.currentuserpost.map((post) => {
      console.log(post, post._id == payload._id);
      if (post._id == payload._id) {
        return payload;
      }
      return post;
    });
  },
  UNLIKE_POST(state, payload) {
    state.posts = state.posts.map((post) => {
      if (post._id == payload._id) {
        return {
          ...post,
          totalLikes: payload.totalLikes,
          like: payload.totalLikes,
          isLiked: payload.isLiked,
        };
      }
      return post;
    });

    state.currentuserpost = state.currentuserpost.map((post) => {
      if (post._id == payload._id) {
        return payload;
      }
      return post;
    });
  },
  SET_CURRENT_USER_ALL_POSTS(state, payload) {
    state.currentuserpost = payload;
    state.posts = payload;
  },
  LOAD_CURRENT_USER_POST_COMMENT(state, payload) {
    state.currentuserpostComments[payload._id] = payload.comments;
  },

  ALL_COMMENTS(state, payload) {
    state.allComments = payload;
  },
  // LOAD_CURRENT_USER_COMMENT_REPLY() {
  //   state.currentusercommentReplies[payload._id] = payload.replies;
  // },
  LOAD_CURRENT_USER_COMMENT_REPLY(state, payload) {
    state.currentusercommentReplies[payload._id] = payload.replies;
  },
  SET_SAVED_POST(state, payload) {
    state.savedPost = payload;
  },
  LIKE_COMMENT(state, payload) {
    if (payload.liketype == "Comment") {
      state.postComments[payload.data.parentId] = state.postComments[
        payload.data.parentId
      ].map((postComment) => {
        if (postComment._id == payload.data._id) {
          return payload.data;
        }
        return postComment;
      });
    } else if (payload.liketype == "Reply") {
      let Data = state.commentReplies[payload.data.parentId].map(
        (postComment) => {
          if (postComment._id == payload.data._id) {
            return payload.data;
          }
          return postComment;
        }
      );

      Vue.set(state.commentReplies, payload.data.parentId, Data);
    }

    // state.commentReplies[payload.parentId] = state.commentReplies[
    //   payload.parentId
    // ].map((postComment) => {
    //   if (postComment._id == payload._id) {
    //     return payload;
    //   }
    //   return postComment;
    // });

    // state.currentuserpostComments = state.currentuserpostComments.map(
    //   (postComments) => {
    //     if (postComments._id == payload.data._id) {
    //       return payload.data;
    //     }
    //     return postComments;
    //   }
    // );
  },
  UNLIKE_COMMENT(state, payload) {
    if (payload.liketype == "Comment") {
      let Data = state.postComments[payload.data.parentId].map(
        (postComment) => {
          if (postComment._id == payload.data._id) {
            return payload.data;
          }
          return postComment;
        }
      );

      Vue.set(state.postComments, payload.data.parentId, Data);
    } else if (payload.liketype == "Reply") {
      let Data = state.commentReplies[payload.data.parentId].map(
        (postComment) => {
          if (postComment._id == payload.data._id) {
            return payload.data;
          }
          return postComment;
        }
      );

      Vue.set(state.commentReplies, payload.data.parentId, Data);
    }

    // state.currentuserpostComments = state.currentuserpostComments.map(
    //   (postComments) => {
    //     if (postComments._id == payload.data._id) {
    //       return payload.data;
    //     }
    //     return postComments;
    //   }
    // );
  },
  DELETE_COMMENT(state, payload) {
    let postIndex = state.posts.findIndex(
      (post) => post._id == payload.parentId
    );
    if (state.posts[postIndex] && state.posts[postIndex].totalComments) {
      state.posts[postIndex].totalComments -= 1;
    }

    Vue.set(state.commentReplies, payload._id, []);

    let Data = state.postComments[payload.parentId].filter(
      (postComment) => postComment._id !== payload._id
    );

    Vue.set(state.postComments, payload.parentId, Data);
  },
  DELETE_REPLY(state, payload) {
    let CommentIndex = state.postComments[payload.postId].findIndex(
      (Comment) => Comment._id == payload.parentId
    );
    if (
      state.postComments[payload.postId][CommentIndex] &&
      state.postComments[payload.postId][CommentIndex].totalReplies
    ) {
      state.postComments[payload.postId][CommentIndex].totalReplies -= 1;
    }

    let Data = state.commentReplies[payload.parentId].filter(
      (Reply) => Reply._id !== payload._id
    );

    Vue.set(state.commentReplies, payload.parentId, Data);
  },
  POLLVOTE(state, payload) {
    let postIndex = state.posts.findIndex((post) => post._id == payload.postId);

    state.posts[postIndex].poll = payload.data;
  },
  GET_USER_CHANEL_FEED(state, payload) {
    state.getUSerchannelfeed = [];
    state.getUSerchannelfeed = payload;
  },

  EDIT_POST(state, payload) {
    state.editPost = payload;
  },
};
