import RepositoryFactory from "@/apis";
const student = RepositoryFactory.student;

export default {
  getStudents({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await student.getStudents(payload);

        commit("SET_STUDENT_DETAIL", { key: "students", value: data.data });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteStudent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await student.deleteStudent(payload);
        console.log(data);

        console.log(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editStudents({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await student.editStudents(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getStudentByUserId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await student.getStudentByUserId(payload);
        commit("SET_STUDENT_LEARNING", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getsinglestudent({ commit }, payload) {
    // console.log(payload);
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await student.getsinglestudent(payload);
        commit("GET_SINGLESTUDENT", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
