import Repository from "../Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";
const GET_ONE_ENTRY = "GetOrderById";
const DELETE_DISPATCH = "DeleteDispatch";
const createrazorpay = "createrazorpay";
export default {
  getAllCollections(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
  getOneCollection(payload) {
    return Repository.post(transformRoute(GET_ONE_ENTRY), payload);
  },
  addOneCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editOneCollection(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteOneCollection(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  deleteDispatchFromOneCollection(payload) {
    return Repository.post(transformRoute(DELETE_DISPATCH), payload);
  },
  getRazorPayCollection(payload) {
    return Repository.post(transformRoute(createrazorpay), payload);
  },
};

const transformRoute = (route) => {
  return `/ecommerce_ny/order/${route}`;
};
