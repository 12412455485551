import RepositoryFactory from "@/apis";
const cart = RepositoryFactory.cart;

export default {
  AddCart({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.AddCart(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getCartData({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.getCartData(payload);

        commit("getCartData", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  removeCart({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.removeCart(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  removeItems({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.removeItems(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
