export default {
  // Home Page
  categories: [],
  single_category: [],
  subcategories: [],
  bannerState: [],
  testimonials: [],
  galleryState: [],
  newsState: [],
  adminAllUsers: [],
  //
  taxState: [],
  //
  couponState: [],
  dispatchState: [],
  placeOfSupply: "maharashtra",
  placeOfSupply2: "Gugarat",
  // Otheres:
  dataa: [],
  productFilterCats: [],
  //temp State
  galleryCurrentState: [],
  contactUsState: [],
  newsLetterState: [],
};
