import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const channel = "channel/";
const USER = "user/";
const feedChannelIdUserid = "/feedChannelIdUserid";
export default {
  getChannelFeeds(payload) {
    return Repository.get(transformRoute(channel) + payload);
  },
  getUserChannelFeeds(payload) {
    return Repository.get(transformRoute(USER) + payload);
  },
  FeedUserIdChannelId(payload) {
    return Repository.post(transformRoute(feedChannelIdUserid), payload);
  },
};

const transformRoute = (route) => {
  return `/feed/${route}`;
};
