import Repository from "@/apis/Repository.js";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const GET_ALL = "";
const GETPAGEBYTAGS = "getpagebytags";
const EDIT_PAGE = "";
const GETPAGEBYCATEGORY = "getpagebycategory";
export default {
  getPages() {
    return Repository.get(transformRoute(GET_ALL));
  },

  getpagebycategory(payload) {
    return Repository.get(transformRoute(GETPAGEBYCATEGORY), {
      params: payload,
    });
  },

  getPageByTags(payload) {
    return Repository.post(transformRoute(GETPAGEBYTAGS), payload);
  },

  getSinglePage(payload) {
    return Repository.get(transformRoute(GET_ALL) + payload.id);
  },

  addPage(payload) {
    return Repository.post(transformRoute(GET_ALL), payload, formDataConfig);
  },

  deletePage(payload) {
    return Repository.delete(transformRoute(payload.id));
  },
  editPage(payload) {
    return Repository.patch(transformRoute(EDIT_PAGE), payload);
  },
};

const transformRoute = (route = "") => {
  return `/cms/page/${route}`;
};
