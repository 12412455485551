export default {
  categories: [],
  selectedData: "",
  course: [],
  author: [],
  selectedCategoryEdit: {},
  selectedCategory: "",
  coursesByCategoryId: [],
  chapter: [],
  chapterByCourseId: [],
  selectedCourse: [],
  selectedCourseHome: "",
  topic: [],
  wishlist: [],
  topicByChapterId: [],
  chapterByCourseId: [],
  cart: [],
  getCart: [],
  filterData: [],
  selectedCat: [],
  videoDuration: [],
  selectedTopics: {},
  comments: [],
  assessments: [],
  certificateCourse: {},
  progressPercentage: 0,
  profileData: [],
  searchCourse: "",

  // getters: {
  //   categoryName(selectedCategoryEdit) {
  //     return selectedCategoryEdit.name;
  //   },
  //   categoryDescription(selectedCategoryEdit) {
  //     return selectedCategoryEdit.description;
  //   }
  // }
};
