import RepositoryFactory from "@/apis";
import { Decrypt } from "@/helpers/CryptoJS";
const members = RepositoryFactory.members;
const user = RepositoryFactory.user;

export default {
  getAllMembers({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const myuserid = rootState.auth.user._id;
        let payloadData = { myuserid: myuserid, ...payload };
        if (payload && payload.search) {
          delete payloadData.myuserid;
        }
        const { data } = await members.getAllMembers({
          ...payloadData,
        });
        commit("SET_ALL_MEMBERS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllAdminMembers({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const myuserid = rootState.auth.user._id;
        let payloadData = { myuserid: myuserid, ...payload };
        if (payload && payload.search) {
          delete payloadData.myuserid;
        }
        const { data } = await members.getAllAdminMembers({
          ...payloadData,
        });
        commit("SET_ALL_MEMBERS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  insertMany({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await members.insertMany(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleMember({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const myuserid = rootState.auth.user._id;
        const { data } = await user.getSingleUser({
          myuserid: myuserid,
          ...payload,
        });

        commit("SET_SINGLE_MEMBER", data.data[0]);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllUsers({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await members.getAllUsers(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
