import Repository from "./Repository";

const formDataConfig = {
    headers: { "Content-Type": "multipart/form-data" }
};

const ADD_WISHLIST = "wishlist/add";
const REMOVE_WISHLIST = "wishlist/remove";
const GET_WISHLIST = "wishlist/"

export default {
    AddWishlist(payload) {
        return Repository.post(transformRoute(ADD_WISHLIST), payload);
    },
    removeWishlist(payload) {
        return Repository.post(transformRoute(REMOVE_WISHLIST), payload);
    },
    getWishlistData(payload) {
        return Repository.get(transformRoute(GET_WISHLIST), payload);
    },
};

const transformRoute = route => {
    return `${route}`;
};
