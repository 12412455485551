import listing from "../../apis/listing";
import page from "../../apis/pages";
import pageCategory from "../../apis/page-category";
import filterTag from "../../apis/filterTag";
import career from "../../apis/career";
export default {
  getListings({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.getListings();

        commit("SET_LISTINGS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  linkGenerator({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.linkGenerator(payload);

        // commit("SET_LISTINGS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  Brochure({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.Brochure(payload);

        // commit("SET_LISTINGS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getBrochure({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.getBrochure(payload);

        // commit("SET_LISTINGS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getAllLink({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.getAllLink(payload);

        // commit("SET_LISTINGS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getHomePageData({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.getHomePageData();

        commit("GET_HOME_PAGE_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteLink({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.deleteLink(payload);

        commit("GET_HOME_PAGE_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  updateLeadership({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.updateLeadership(payload);

        // commit("GET_HOME_PAGE_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getAllLeadershipData({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.getAllLeadershipData();
        // console.log(data.data);

        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i]._id == "National Secretariat") {
            commit("NATIONAL_SECRETARIAT_DATA", data.data[i].data);
          }
          if (data.data[i]._id == "Board Members") {
            commit("BOARD_MEMBERS_DATA", data.data[i].data);
          }
          if (data.data[i]._id == "Young Talent Council") {
            commit("YOUNG_TALENT_COUNCIL_DATA", data.data[i].data);
          }
          if (data.data[i]._id == "Past National Presidents") {
            commit("PAST_NATIONAL_DATA", data.data[i].data);
          }
          if (data.data[i]._id == "Mentor") {
            commit("MENTOR_DATA", data.data[i].data);
          }
          if (data.data[i]._id == "GATT") {
            commit("GATT_DATA", data.data[i].data);
          }
          if (data.data[i]._id == "Special Invities") {
            commit("SPECIAL_INVITIES_DATA", data.data[i].data);
          }
          if (data.data[i]._id == "National Strategic Group") {
            commit("NATIONAL_STRATEGIC_GROUP_DATA", data.data[i].data);
          }
          if (data.data[i]._id == "Chapter Excellence Group") {
            commit("CHAPTER_EXCELLENCE_GROUP_DATA", data.data[i].data);
          }
        }
        // commit("GET_LEADERSHIP_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  CreateHomePageData({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.CreateHomePageData(payload);

        // commit("GET_HOME_PAGE_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  Publish({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.Publish(payload);

        // commit("GET_HOME_PAGE_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  createLeadership({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.createLeadership(payload);

        // commit("GET_HOME_PAGE_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getAllHomePageData({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.getAllHomePageData();

        commit("GET_ALL_HOME_PAGE_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteNewsletter({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.deleteNewsletter(payload);

        // commit("SET_NEWSLETTERS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  updateNewsletter({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.updateNewsletter(payload);

        // commit("SET_NEWSLETTERS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getNewsletter({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.getNewsletter(payload);

        commit("SET_NEWSLETTERS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  createNewsletter({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await listing.createNewsletter(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getpagebycategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.getpagebycategory(payload);
        if (payload.type == "Event") {
          commit("SET_CATEGORYWISEPAGE", data.data);
        }
        if (payload.type == "Blog") {
          commit("SET_BLOGPAGES", data.data);
        }
        if (payload.type == "Podcast") {
          commit("SET_PODCASTPAGES", data.data);
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getPages({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.getPages();

        commit("SET_PAGES", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getPageByTags({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.getPageByTags(payload);

        commit("SET_PAGES", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getPage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.getSinglePage(payload);

        commit("SET_PAGE", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  addPage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.addPage(payload);
        // commit("ADD_PAGE", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getPageCategories({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await pageCategory.getPageCategories(payload);

        commit("SET_PAGE_CATEGORIES", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getTagsbyCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await filterTag.getTagsbyCategory(payload);

        commit("SET_CATEGORIES_TAGS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getfilterbycategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await filterTag.getfilterbycategory(payload);

        commit("SET_FILTERS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetTagsbyFilter({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await filterTag.GetTagsbyFilter(payload);

        commit("SET_FILTER_TAGS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deletePage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.deletePage(payload);
        if (payload.type == "Podcast") {
          commit("DELETE_PODCAST", data.data);
        }
        if (payload.type == "Event") {
          commit("DELETE_PAGE", data.data);
        }
        if (payload.type == "Blog") {
          commit("DELETE_BLOG", data.data);
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getRelatedPages({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.getPages(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  subscribeToNewsletter({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await pageCategory.subscribeToNewsletter(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editPage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.editPage(payload);
        // commit("EDIT_PAGE", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addCareer({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await career.addCareer(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getCareers({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await career.getCareers(payload);
        commit("SET_CAREERS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteCareer({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await career.deleteCareer(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editCareer({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await career.editCareer(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
