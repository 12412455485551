import Repository from "../Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";
const FULL_PRODUCT = "fullProduct";
const GET_ONE_ENTRY = "getSingle";
const FILTER_BY_CATEGORY = "filterByCategory";
// const MAIN_GET = "tempMainGET";
const MAIN_GET = "mainGET";
const SIZE_PROD_ID = "sizeprodId";

export default {
  getAllCollections(payload) {
    console.log(payload);
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
  getMainAllCollection(payload) {
    return Repository.post(transformRoute(MAIN_GET), payload);
  },
  full_product(payload) {
    return Repository.post(transformRoute(FULL_PRODUCT), payload);
  },
  getOneCollection(payload) {
    return Repository.post(transformRoute(GET_ONE_ENTRY), payload);
  },
  getSizeProdId(payload) {
    return Repository.post(transformRoute(SIZE_PROD_ID), payload);
  },
  filterByCategory(payload) {
    return Repository.post(transformRoute(FILTER_BY_CATEGORY), payload);
  },
  addOneCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editOneCollection(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteOneCollection(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/ecommerce_ny/product/${route}`;
};
