export default {
  product: [],
  SingleProductById: {},
  username: "",
  colorVariantState: [],
  sizeVariantState: [],
  diamondsState: [],
  breadcrums: {},
  productState: [],
  mainProductState: [],
  singleProductAdminState: [],
  singleProductState: [],
  similarProducts: [],
  allDetailsOfProduct: [],

  cartState: [],
};
