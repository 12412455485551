export default {
  SET_STUDENT_DETAIL(state, payload) {
    state[payload.key] = payload.value;
  },
  SET_USER_DETAILS(state, payload) {
    state.singleUser = payload;
  },
  SET_ALL_USER_DETAILS(state, payload) {
    state.allUsers = payload;
  },
  CREATE_USER_DETAILS(state, payload) {
    state.createUser = payload;
  },
};
