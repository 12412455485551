import Repository from "@/apis/Repository.js";

const ADD_CAREER = "";
const EDIT_CAREER = "";
const GET_CAREER = "";
export default {
  addCareer(payload) {
    return Repository.post(transformRoute(ADD_CAREER), payload);
  },

  editCareer(payload) {
    return Repository.patch(transformRoute(EDIT_CAREER), payload);
  },
  getCareers(payload) {
    return Repository.get(transformRoute(GET_CAREER), payload);
  },
  deleteCareer(payload) {
    return Repository.delete(transformRoute(payload));
  },
};

const transformRoute = (route = "") => {
  return `/cms/career/${route}`;
};
