import Repository from "./Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";

export default {
  getAllCollections() {
    return Repository.get(transformRoute(GET_ENTRY));
  },
  addOneCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editOneCollection(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteOneCollection(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/ecommerce_ny/tax/${route}`;
};
