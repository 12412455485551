import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const CREATE = "/nhrdn/award/create";
const GETDATA = "/nhrdn/award/getAll";
const DOWNLOADEXCEL = "/nhrdn/award/downloadExcel";
const UPDATE = "/nhrdn/award/update";
const GETSINGLE = "/nhrdn/award/getSingle";

export default {
  create(payload) {
    return Repository.post(`${CREATE}`, payload);
  },
  getAll(payload) {
    return Repository.post(`${GETDATA}`, payload);
  },
  getSingle(payload) {
    return Repository.post(`${GETSINGLE}`, payload);
  },
  update(payload) {
    return Repository.post(`${UPDATE}`, payload);
  },
  downloadExcel(payload) {
    return Repository.post(`${DOWNLOADEXCEL}`, payload);
  },
  //   getRegisterPaymnetData(payload) {
  //     return Repository.post(`${GETPAYMENTDATA}`, payload);
  //   },
};
