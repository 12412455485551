import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" }
};

const PLACE_ORDER = "placeOrder/";
const UPDATE_STATUS = "placeOrder/update";

export default {
  placeOrder(payload) {
    return Repository.post(transformRoute(PLACE_ORDER), payload);
  },
  updateStatus(payload) {
    return Repository.post(transformRoute(UPDATE_STATUS), payload);
  }
};

const transformRoute = route => {
  return `${route}`;
};
