export default {
  openLoginPopup: 0,
  OrderReturnImagePopupToggle: false,
  OrderReturnVideoPopupToggle: false,
  rejectedReasonToggle: false,
  rejectedReasonState: [],
  OrderReturnImagePopupState: [],
  //
  universalPopUpToggle: false,
  dispatcherToggle: false,
  personalDetailsToggle: false,
};
