import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const ADD_LEARINING = "add/";
const UPDATE_LEARINING = "Edit/";
const GET_SINGLE = "getSingle/";
const GET_ATTENDEES = "getCourseByStudentId/";

export default {
  AddLearningData(payload) {
    return Repository.post(transformRoute(ADD_LEARINING), payload);
  },
  updateLearning(payload) {
    return Repository.post(transformRoute(UPDATE_LEARINING), payload);
  },
  getSingle(payload) {
    let NEW_ROUTE =
      GET_SINGLE +
      "?course=" +
      payload.course +
      "&student=" +
      payload.student +
      "&chapter=" +
      payload.chapter +
      "&topic=" +
      payload.topic;
    return Repository.get(transformRoute(NEW_ROUTE), payload);
  },
  getCourseByStudentId(payload) {
    return Repository.post(transformRoute(GET_ATTENDEES), payload);
  },
};

const transformRoute = (route) => {
  return `/learningTracker/${route}`;
};
