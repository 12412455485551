import Repository from "./Repository";
import { Encript } from "@/helpers/CryptoJS";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const GETALL = "get";
const GETALLUSERS = "getAllUsers";
const GETALLADMINMEMBERS = "getAllAdminMembers";
const INSERTMANY = "insertMany";

export default {
  getAllMembers(payload) {
    return Repository.post(transformRoute(GETALL), payload);
  },
  getAllAdminMembers(payload) {
    return Repository.post(transformRoute(GETALLADMINMEMBERS), payload);
  },
  insertMany(payload) {
    return Repository.post(transformRoute(INSERTMANY), payload);
  },
  getAllUsers(payload) {
    return Repository.post(transformRoute(GETALLUSERS), payload);
  },
};

const transformRoute = (route) => {
  return `/nhrdn/user/${route}`;
};
