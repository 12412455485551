import Repository from "../Repository";
const ADD_ENTRY = "add";
// const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";
const GET_SINGLE_ENTRY = "getSingle";
const GET_SINGLE_IMAGE_ENTRY = "GetSingleImages";
const GET_SINGLE_ERROR_ENTRY = "GetSingleVideos";
const GET_REJECTED_REASON = "GetRejectedReason";

export default {
  getAllCollections() {
    return Repository.post(transformRoute(GET_ENTRY));
  },
  getSingleCollections(payload) {
    return Repository.post(transformRoute(GET_SINGLE_ENTRY), payload);
  },
  getMediaImageCollections(payload) {
    return Repository.post(transformRoute(GET_SINGLE_IMAGE_ENTRY), payload);
  },
  getMediaVideoCollections(payload) {
    return Repository.post(transformRoute(GET_SINGLE_ERROR_ENTRY), payload);
  },
  getRejectedReasonCollections(payload) {
    return Repository.post(transformRoute(GET_REJECTED_REASON), payload);
  },
  addOneCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editOneCollection(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  //   MoveToCartFromWishlist(payload) {
  //     return Repository.post(transformRoute(ADD_TO_CART), payload);
  //   },
  //   deleteOneCollection(payload) {
  //     return Repository.post(transformRoute(DELETE_ENTRY), payload);
  //   },
};

const transformRoute = (route) => {
  return `/ecommerce_ny/orderReturns/${route}`;
};
