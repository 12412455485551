import Repository from "./Repository";
const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const REGISTER = "/signupWithPassword";
const GENERATE_OTP = "/GenerateOTP";
const VERIFYOTP = "/VerifyOTP";
const LOGIN = "/loginWithPassword";
const LOGOUT = "/logout";
const FORGOT_PASSWORD = "/forgotPassword";
const UPDATEPROFILE = "/nhrdn/user/update";
const updateProfilePic = "/nhrdn/user/update";
const updateCV = "/nhrdn/user/update";
const GETSINGLE_USER = "/getsingleUser";
const getOtp = "/GenerateOTP";
const forgotPass = "/forgotPassword";
const resetpasswordLink = "/ResetPasswordLink";

export default {
  getOtp(payload) {
    return Repository.post(transformRoute(getOtp), payload);
  },
  ForgotPass(payload) {
    return Repository.post(transformRoute(forgotPass), payload);
  },
  signupWithPassword(payload) {
    return Repository.post(transformRoute(REGISTER), payload);
  },
  generateOTP(payload) {
    return Repository.post(transformRoute(GENERATE_OTP), payload);
  },
  VerifyOTP(payload) {
    return Repository.post(transformRoute(VERIFYOTP), payload);
  },
  loginWithPassword(payload) {
    return Repository.post(transformRoute(LOGIN), payload);
  },
  logout(payload) {
    return Repository.post(transformRoute(LOGOUT), payload);
  },
  forgotPassword(payload) {
    return Repository.post(transformRoute(FORGOT_PASSWORD), payload);
  },
  updateProfile(payload) {
    return Repository.post(UPDATEPROFILE, payload);
  },
  updateProfilePic(payload) {
    return Repository.post(updateProfilePic, payload, formDataConfig);
  },
  updateCV(payload) {
    return Repository.post(updateCV, payload, formDataConfig);
  },
  getsingleProfile(payload) {
    return Repository.post(transformRoute(GETSINGLE_USER), payload);
  },
  resetPasswordLink(payload) {
    return Repository.post(transformRoute(resetpasswordLink), payload);
  },
};

const transformRoute = (route) => {
  return `/auth${route}`;
};
