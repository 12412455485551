import RepositoryFactory from "@/apis";
const channel = RepositoryFactory.channel;

export default {
  CreateChannel({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.CreateChannel(payload);

        // Append in channel list
        commit("ADD_CHANNEL", { ...data.data, isMember: true });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.createCategory(payload);

        resolve(data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  },
  editCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.editCategory(payload);

        resolve(data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  },
  deleteCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.deleteCategory(payload);

        resolve(data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  },
  GetALLChannel({ commit, dispatch, state }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.GetAllChannel(payload);
        // console.log(data.data);
        commit("SET_ALL_CHANNELS", data.data);
        const index = data.data.result.findIndex((x) => x.isMember);
        if (index !== -1 && state.selectedChannel === null) {
          dispatch("GetChannel", { channelID: data.data.result[index]._id });
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetChannel({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.GetChannel(payload);
        // console.log(data.data[0]);
        commit("SET_SELECTED_CHANNEL", data.data[0]);
        let Length =
          data.data[0].channelMembers.length > 10
            ? 10
            : data.data[0].channelMembers.length;
        let tenChannelMember = [];
        for (let i = 0; i < Length; i++) {
          tenChannelMember.push(data.data[0].channelMembers[i]);
        }
        // commit("SET_SELECTED_CHANNEL_MEMBERS", data.data[0].channelMembers);
        commit("SET_SELECTED_CHANNEL_MEMBERS", tenChannelMember);
        commit(
          "SET_SELECTED_CHANNEL_JOIN_REQUESTS",
          data.data[0].channelJoinRequests
        );

        let payload1 = [];
        for (let i = 0; i < data.data[0].channelMembers.length; i++) {
          if (
            data.data[0].channelMembers[i].role !== "member" &&
            data.data[0].channelMembers[i].role !== "admin" &&
            data.data[0].channelMembers[i].role !== "owner"
            //  "owner" ||
            // "admin" ||
            // "president" ||
            // "vice_president" ||
            // "secretary" ||
            // "treasurer" ||
            // "assitant_secretary" ||
            // "committee_member" ||
            // "channel_manager"
          ) {
            payload1.push(data.data[0].channelMembers[i]);
          }
        }
        console.log(payload1);
        commit("CHANNEL_KEY_MEMBER", payload1);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  joinChannel({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.joinChannel(payload);

        commit("ADD_JOIN_REQUEST", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  acceptJoin({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.acceptJoin(payload);

        delete payload.userID;
        dispatch("GetChannel", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  rejectJoin({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.rejectJoin(payload);

        commit("REMOVE_JOIN_REQUEST", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  removeMember({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.removeMember(payload);

        delete payload.UserID;
        delete payload.channelID;
        dispatch("GetChannel", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getNonMembers({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.getNonMembers(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  memberbyChannelId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.memberbyChannelId(payload);
        if (payload && payload["offset"]) {
          commit("MEMBER_BYCHANNEL", data.data);
        } else {
          commit("SET_MEMBER_BYCHANNEL", data.data);
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  searchChannelMembers({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.memberbyChannelId(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addMember({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.addMember(payload);

        delete payload.UserID;
        dispatch("GetChannel", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editChannel({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.editChannel(payload);

        dispatch("GetChannel", { channelID: payload.get("channelID") });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  adminChannelEdit({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.adminChannelEdit(payload);

        dispatch("GetChannel", { channelID: payload.get("channelID") });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateRole({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.updateRole(payload);

        delete payload.channelMemberID;
        delete payload.role;
        dispatch("GetChannel", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  DeleteChannel({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.DeleteChannel(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetMemberById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.GetMemberById(payload);
        commit("GET_MEMBER_BY_ID", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getChannelCategories({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.getChannelCategories(payload);
        commit("SET_CHANNEL_CATEGORIES", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
