export default {
  SET_STUDENT_DETAIL(state, payload) {
    state[payload.key] = payload.value;
  },
  SET_STUDENT_LEARNING(state, payload) {
    state.studentLearning = payload;
  },
  GET_SINGLESTUDENT(state, payload) {
    state.get_singlestudent = payload;
  },
};
