export default {
  SET_ALL_MEMBERS(state, payload) {
    state.allMembers = payload;
  },
  SET_SINGLE_MEMBER(state, payload) {
    state.singleMembers = payload;
  },
  SET_ALL_REQUEST(state, payload) {
    state.requestlist = payload;
  },
  SET_ALL_CONNECTION(state, payload) {
    state.connectionlist = payload;
  },
};
