import RepositoryFactory from "@/apis";
const post = RepositoryFactory.post;
const feed = RepositoryFactory.feed;
const user = RepositoryFactory.user;
const channel = RepositoryFactory.channel;

export default {
  getChannelPosts({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await feed.getChannelFeeds(payload);

        commit("SET_ALL_POSTS", data.data.feed);

        for (let post of data.data.feed) {
          commit("LOAD_POST_COMMENT", post);
          for (let comment of post.comments) {
            commit("LOAD_COMMENT_REPLY", comment);
          }
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  setUserChannelFeeds({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await feed.getUserChannelFeeds(payload);

        commit("SET_CURRENT_USER_ALL_POSTS", data.data.feed);

        for (let post of data.data.feed) {
          commit("LOAD_CURRENT_USER_POST_COMMENT", post);
          for (let comment of post.comments) {
            commit("LOAD_CURRENT_USER_COMMENT_REPLY", comment);
          }
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  CreatePost({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.CreatePost(payload);
        commit("LOAD_POST_COMMENT", data.data);
        // for (let comment of data.data.comments) {
        //   console.log("Comment", comment);
        //   commit("LOAD_COMMENT_REPLY", comment);
        // }
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createWithMedia({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.createWithMedia(payload);
        // console.log(data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetPostingChannel({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.getChannels(payload);
        // console.log(data.data)
        commit("SET_POSTING_CHANNELS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addComment({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.addComment(payload);
        commit("ADD_POST_COMMENT", {
          ...data.data,
          _user: rootState.auth.user,
        });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addReply({ commit, rootState }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.addReply(payload);
        commit("ADD_COMMENT_REPLY", {
          ...data.data,
          _user: rootState.auth.user,
          postId: payload.postId,
        });
        commit("COUNT_INCREMENT_COMMENT", payload);

        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  likePost({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.like(payload);
        commit("LIKE_POST", data.data);

        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  unlikePost({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.unlike(payload);
        commit("UNLIKE_POST", data.data);

        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  createPoll({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.createPoll(payload);

        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  votePoll({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let postId = payload.postId;
        delete payload.postId;
        const { data } = await post.votePoll(payload);
        commit("POLLVOTE", { data: data.data, postId: postId });
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  savePost({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.savePost(payload);

        commit("SET_SAVED_POST", data);

        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  unsavePost({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.unsavePost(payload);

        // commit("SET_SAVED_POST", data);

        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  likeComment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.likeComment(payload);
        commit("LIKE_COMMENT", { data: data.data, liketype: payload.liketype });
        // commit("LIKE_COMMENT", data.data);

        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  unlikeComment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.unlikeComment(payload);
        commit("UNLIKE_COMMENT", {
          data: data.data,
          liketype: payload.liketype,
        });
        // commit("UNLIKE_COMMENT", data);

        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  deletePost({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.deletePost(payload);
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  admindeletePost({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // const { data } = await post.admindeletePost(payload);
        const { data } = await post.admindeletePost(payload);

        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  UserEditPost({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      console.log(payload);
      try {
        const { data } = await post.editPost(payload);
        commit("EDIT_POST", payload);
        // console.log(data);
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  deleteComment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let Comment = {
          commentID: payload._id,
        };
        const { data } = await post.deleteComment(Comment);
        commit("DELETE_COMMENT", payload);
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  deleteReply({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let Comment = {
          commentID: payload._id,
        };
        const { data } = await post.deleteReply(Comment);
        commit("DELETE_REPLY", payload);
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  LikeCounts({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.LikeCounts(payload);
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  LikeCountUsers({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.LikeCountUsers(payload);
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  FeedUserIdChannelId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await feed.FeedUserIdChannelId(payload);
        // console.log(data.data);
        commit("GET_USER_CHANEL_FEED", data.data);
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  displayAllComment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post.displayAllComment(payload);
        commit("ALL_COMMENTS", data);
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
};
