import contact from "../../apis/contactUs";
export default {
  create({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await contact.create(payload);

        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  },
};
