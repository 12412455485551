import Repository from "./Repository";
import axios from "axios";

const formDataConfig = {
    headers: { "Content-Type" : "multipart/form-data" }
};

const create = "create";
const update = "update";
const getAll = "getAll";
const remove = "delete";


export default{
    create(payload){
        return Repository.post(transformRoute(create), payload);
    },
    update(payload){
        return Repository.post(transformRoute(update), payload);
    },
    remove(payload){
        return Repository.post(transformRoute(remove), payload);
    },
    getAll(payload){
        return Repository.post(transformRoute(getAll), payload);
    },
}

const transformRoute = (route) => {
    return `/nhrdn/benefits/${route}`;
  };
