import Vue from "vue";
import VueRouter from "vue-router";
import adminRoutes from "@/admin/routes/Index";
import ChannelRoutes from "../channel/routes";
import SocialRouter from "../social/routes/index.js";
// import EcommerceRouter from "@/ecommerce/routes/index.js";
import ecomroutese from "../pages/e-commerce-1/index";
import auth from "@/store";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "main",
      component: () => import("@/main/Main.vue"),
      redirect: "home",
      children: [
        // ...ecomroutese,
        ...SocialRouter,

        ...adminRoutes,
        ...ChannelRoutes,
        // ...EcommerceRoutes,
        {
          path: "home",
          name: "home",
          component: () => import("@/pages/home/Home.vue"),
        },
        {
          path: "events",
          name: "events",
          component: () => import("@/pages/events/Events.vue"),
        },
        {
          path: "eventnew",
          name: "eventnew",
          component: () => import("@/pages/events/components/Eventnew.vue"),
        },
        {
          path: "upskill",
          name: "upskill",
          component: () => import("@/pages/upskill/Upskill.vue"),
        },
        {
          path: "conference",
          name: "conference",
          component: () => import("@/pages/conference/Conference.vue"),
        },
        {
          path: "bestofbreed",
          name: "bestofbreed",
          component: () => import("@/pages/best/Best.vue"),
        },
        {
          path: "/connect",
          name: "connect",
          component: () => import("@/pages/contact/Contact.vue"),
          // redirect: "channel-discussion",
        },
        {
          path: "privacy-policy",
          name: "privacy-policy",
          component: () => import("@/pages/privacy/Privacy.vue"),
        },
        // {
        //   path: "decode-delloy",
        //   name: "decode-delloy",
        //   component: () => import("@/pages/conference/components/Delloy.vue"),
        // },
        {
          path: "news",
          name: "news",
          component: () => import("@/pages/newsletter/NewsLetter.vue"),
        },
        {
          path: "nhrd-jobs/:id",
          name: "nhrd-jobs",
          component: () => import("@/pages/nhrd-jobs/NHRD-Jobs.vue"),
        },
        {
          path: "resources",
          name: "resources",
          component: () => import("@/pages/community/Community.vue"),
        },
        {
          path: "podcast",
          name: "podcast",
          component: () => import("@/pages/podcast/Podcast.vue"),
        },
        {
          path: "knowledgeHub-podcast",
          name: "knowledgeHub-podcast",
          component: () =>
            import("@/pages/podcast/components/knowledgehubPodcast.vue"),
        },
        {
          path: "chapters",
          name: "chapters",
          component: () => import("@/pages/chapters/Chapters.vue"),
        },
        // {
        {
          path: "profile",
          name: "profile",
          component: () => import("@/pages/profile/Profile.vue"),
        },
        {
          path: "edit",
          name: "edit",
          component: () => import("@/pages/profile/Edit.vue"),
        },
        {
          path: "recruter",
          name: "recruter",
          component: () => import("@/pages/recruter/Recruter.vue"),
        },
        {
          path: "nhrd-board",
          name: "nhrd-board",
          component: () => import("@/pages/nhrd-board/NHRD-Board"),
        },
        {
          path: "board-member",
          name: "board-member",
          component: () => import("@/pages/board/Board.vue"),
        },
        {
          path: "membership",
          name: "membership",
          component: () => import("@/pages/membership/Membership.vue"),
        },
        {
          path: "membership/success",
          name: "membership-success",
          component: () =>
            import("@/pages/feedbackPages/SuccessPaymentPage.vue"),
        },
        {
          path: "conference/success",
          name: "conference-success",
          component: () =>
            import("@/pages/conference/components/RegisterSuccessPage.vue"),
        },
        {
          path: "membership/failure",
          name: "membership-failure",
          component: () =>
            import("@/pages/feedbackPages/FailurePaymentPage.vue"),
        },
        {
          path: "conference/failure",
          name: "conference-failure",
          component: () =>
            import("@/pages/conference/components/RegisterFailurePage.vue"),
        },
        {
          path: "invoice/:id",
          name: "invoice",
          component: () => import("@/pages/invoice/Invoice.vue"),
        },
        {
          path: "offinvoice/:id",
          name: "offlineinvoice",
          component: () => import("@/pages/invoice/offlineInvoice.vue"),
        },
        {
          path: "registerInvoice/:id",
          name: "registerInvoice",
          component: () => import("@/pages/invoice/registerInvoice.vue"),
        },
        {
          path: "awardInvoice/:id",
          name: "awardInvoice",
          component: () => import("../../src/pages/invoice/awardInvoice.vue"),
          // @/pages/invoice/awardInvoice.vue
        },
        {
          path: "blog/:id",
          name: "blog",
          component: () => import("@/pages/blog/Blog.vue"),
        },
        {
          path: "social",
          name: "social",
          component: () => import("@/pages/social/Social.vue"),
        },
        {
          path: "job",
          name: "job",
          component: () => import("@/pages/job/Job.vue"),
        },
        {
          path: "job/applied-job",
          name: "applied",
          component: () => import("@/pages/job/Applied.vue"),
        },
        {
          path: "job/offer",
          name: "offer",
          component: () => import("@/pages/job/Offer.vue"),
        },
        {
          path: "job/applicant/:title",
          name: "applicant",
          component: () => import("@/pages/job/Applicant.vue"),
        },
        {
          path: "job/shortlist",
          name: "shortlist",
          component: () => import("@/pages/job/ShortList.vue"),
        },
        {
          path: "job/add",
          name: "addjob",
          component: () => import("@/pages/job/addjob.vue"),
        },
        {
          path: "job/edit",
          name: "editjob",
          component: () => import("@/pages/job/editjob.vue"),
        },
        {
          path: "network",
          name: "network",
          component: () => import("@/pages/network/Network.vue"),
        },
        // E-learning
        {
          path: "upskill",
          name: "upskill",
          component: () => import("@/pages/upskill/Upskill.vue"),
        },
        {
          path: "upskill/course",
          name: "e-course-detail",
          component: () =>
            import("@/pages/e-learning/product/coursedetail1/CourseDetail.vue"),
        },
        // {
        //   path: "upskill/my-learnings",
        //   name: "my-learnings",
        //   component: () =>
        //     import("@/pages/e-learning/my-learning/MyLearning.vue"),
        // },
        {
          path: "upskill/learningCourse",
          name: "learningCourses",
          component: () =>
            import("@/pages/e-learning/learningCourses/Course.vue"),
        },
        {
          path: "e-learning/courses",
          name: "courses",
          component: () => import("@/pages/e-learning/courses/Courses.vue"),
        },
        {
          path: "e-learning/cart",
          name: "cart",
          component: () => import("@/pages/e-learning/cart/Cart.vue"),
        },
        // E-learning
        {
          path: "/e-learning/home",
          name: "e-home",
          component: () => import("@/pages/e-learning/home/Home.vue"),
        },
        {
          path: "/e-learning/all-categories",
          name: "e-all-categories",
          component: () =>
            import("@/pages/e-learning/all-categories/AllCategories.vue"),
        },
        {
          path: "/e-learning/all-courses",
          name: "e-courses",
          component: () => import("@/pages/e-learning/product/All-Courses.vue"),
        },
        // {
        //   path: "e-learning/course-detail",
        //   name: "e-course-detail",
        //   component: () =>
        //     import("@/pages/e-learning/product/coursedetail/CourseDetail.vue"),
        // },
        // {
        //   path: "e-learning/my-learning",
        //   name: "e-my-learning",
        //   component: () =>
        //     import("@/pages/e-learning/my-learning/MyLearning.vue"),
        // },
        {
          path: "/my-learning/certificate",
          name: "e-my-learning-certificate",
          component: () =>
            import("@/pages/e-learning/certificate/Certificate.vue"),
        },
        {
          path: "/my-learning/e-certificate",
          name: "e-my-learning-e-certificate",
          component: () =>
            import("@/pages/e-learning/my-learning/components/Certificate.vue"),
        },
        {
          path: "/e-learning/cart",
          name: "e-cart",
          component: () => import("@/pages/e-learning/cart/Cart.vue"),
        },
        {
          path: "/e-learning/profile",
          name: "e-profile",
          component: () => import("@/pages/e-learning/profile/Profile.vue"),
        },
        {
          path: "/e-learning/product-all",
          name: "product-all",
          component: () => import("@/pages/e-learning/product/All-Courses.vue"),
        },
        {
          path: "/e-learning/wishlist",
          name: "e-wishlist",
          component: () =>
            import("@/pages/e-learning/product/wishlist/Wishlist.vue"),
        },
        {
          path: "/e-learning/course",
          name: "course",
          component: () => import("@/pages/e-learning/course/Course.vue"),
        },
        // {
        //   path: "e-learning/learningCourse",
        //   name: "learningCourses",
        //   component: () => import("@/pages/e-learning/learningCourses/Course.vue"),
        // },

        // E-learning CG  to  NHRDN
        {
          path: "e-learning/course-detail",
          name: "e-course-detail",
          component: () =>
            import("@/pages/e-learning/product/coursedetail1/CourseDetail.vue"),
        },
        {
          path: "e-learning/my-learnings",
          name: "e-my-learning",
          component: () =>
            import("../pages/e-learning/my-learning/MyLearning.vue"), //UI Done - Uncomment API Part
        },
        {
          path: "e-learning/all-courses",
          name: "e-all-categories",
          component: () =>
            import("@/pages/e-learning/all-categories/AllCategories.vue"),
        },
        {
          path: "/e-learning/cart",
          name: "e-cart",
          component: () => import("@/pages/e-learning/cart/Cart.vue"), //Binding left
        },
        {
          path: "e-learning/learningCourse",
          name: "learningCourses",
          component: () =>
            import("@/pages/e-learning/learningCourses/Course.vue"),
        },
        // {
        //   path: "/e-learning/home",
        //   name: "e-home",
        //   component: () => import("@/pages/e-learning/home/Home.vue"),
        // },
        // {
        //   path: "/e-learning/all-courses",
        //   name: "e-courses",
        //   component: () => import("@/pages/e-learning/product/All-Courses.vue"),
        // },
        {
          path: "/chatboat",
          name: "chatboat",
          component: () => import("@/pages/ChatBoat.vue"),
        },
        {
          path: "/my-learning/certificate",
          name: "e-my-learning-certificate",
          component: () =>
            import("@/pages/e-learning/certificate/Certificate.vue"),
        },
        {
          path: "/my-learning/e-certificate",
          name: "e-my-learning-e-certificate",
          component: () =>
            import("@/pages/e-learning/my-learning/components/Certificate.vue"),
        },
        {
          path: "/e-learning/profile",
          name: "e-profile",
          component: () => import("@/pages/e-learning/profile/Profile.vue"),
        },
        {
          path: "/e-learning/product-all",
          name: "product-all",
          component: () => import("@/pages/e-learning/product/All-Courses.vue"),
        },
        {
          path: "/e-learning/wishlist",
          name: "e-wishlist",
          component: () =>
            import("@/pages/e-learning/product/wishlist/Wishlist.vue"),
        },
        {
          path: "/e-learning/course",
          name: "course",
          component: () => import("@/pages/e-learning/course/Course.vue"),
        },
        {
          path: "award",
          name: "award",
          component: () => import("@/pages/award/Award.vue"),

          redirect: "/award/overview",
          children: [
            {
              path: "silver",
              name: "silver",
              component: () =>
                import("@/pages/award/components/Chapters/Silver.vue"),
            },
          ],
        },
        // NHRDN New E-learning Try Upskill as per figma UX
        // {
        //   path: "upskill/courses",
        //   name: "courses",
        //   component: () =>
        //     import("@/pages/e-learning/courses/components/Info.vue"),
        //   // redirect: "/courseHome",
        //   children: [
        //     {
        //       path: "course-home",
        //       name: "courseHome",
        //       component: () =>
        //         import("@/pages/e-learning/courses/components/Overview.vue"),
        //     },

        //     {
        //       path: "video-tutorial",
        //       name: "courseVideo",
        //       component: () =>
        //         import(
        //           "@/pages/e-learning/learningCourses/components/Video.vue"
        //         ),
        //     },
        //     {
        //       path: "pdf-tutorial",
        //       name: "coursePDF",
        //       component: () =>
        //         import("@/pages/e-learning/learningCourses/components/Pdf.vue"),
        //     },
        //   ],
        // },
        {
          path: "awards",
          name: "awards",
          component: () => import("@/pages/awards/Discover.vue"),
          redirect: "/awards/silver",
          children: [
            {
              path: "silver",
              name: "silver-aw",
              component: () =>
                import("@/pages/awards/components/chapters/Silver.vue"),
              // D:\brained-projects\NHRDN\nhrdn-vue\src\pages\discover\components\chapters\D-Overview.vue
            },
            {
              path: "national",
              name: "national-aw",
              component: () =>
                import("@/pages/awards/components/chapters/National.vue"),
              // D:\brained-projects\NHRDN\nhrdn-vue\src\pages\discover\components\chapters\D-Overview.vue
            },
            {
              path: "award",
              name: "award-aw",
              component: () =>
                import("@/pages/awards/components/chapters/Award.vue"),
              // D:\brained-projects\NHRDN\nhrdn-vue\src\pages\discover\components\chapters\D-Overview.vue
            },
            {
              path: "firstace",
              name: "firstace",
              component: () =>
                import("@/pages/awards/components/chapters/Firstace.vue"),
              // D:\brained-projects\NHRDN\nhrdn-vue\src\pages\discover\components\chapters\D-Overview.vue
            },
          ],
        },
        {
          path: "discover",
          name: "discover",
          component: () => import("@/pages/discover/Discover.vue"),

          redirect: "/discover/announcement",
          children: [
            {
              path: "announcement",
              name: "d-announcement",
              component: () =>
                import(
                  "@/pages/discover/components/chapters/D-announcement.vue"
                ),
              // D:\brained-projects\NHRDN\nhrdn-vue\src\pages\discover\components\chapters\D-Overview.vue
            },
            {
              path: "overview",
              name: "d-key-overview",
              component: () =>
                import("@/pages/discover/components/chapters/D-Overview.vue"),
              // D:\brained-projects\NHRDN\nhrdn-vue\src\pages\discover\components\chapters\D-Overview.vue
            },
            {
              path: "key-objective",
              name: "d-key-objective",
              component: () =>
                import(
                  "@/pages/discover/components/chapters/D-Key-Objective.vue"
                ),
              // D:\brained-projects\NHRDN\nhrdn-vue\src\pages\discover\components\chapters\D-Overview.vue
            },
            {
              path: "philosophy",
              name: "d-key-philosophy",
              component: () =>
                import("@/pages/discover/components/chapters/D-philosophy.vue"),
              // D:\brained-projects\NHRDN\nhrdn-vue\src\pages\discover\components\chapters\D-Overview.vue
            },
            {
              path: "history",
              name: "d-key-history",
              component: () =>
                import("@/pages/discover/components/chapters/D-history.vue"),
            },
            {
              path: "value-proposition",
              name: "d-key-proposition",
              component: () =>
                import("@/pages/discover/components/chapters/D-philosophy.vue"),
            },
            {
              path: "memorandom",
              name: "d-key-memorandom",
              component: () =>
                import("@/pages/discover/components/chapters/D-memorandom.vue"),
              // D:\brained-projects\NHRDN\nhrdn-vue\src\pages\discover\components\chapters\D-Overview.vue
            },
            {
              path: "value",
              name: "valuepro",
              component: () =>
                import(
                  "@/pages/discover/components/chapters/ValuePropositon.vue"
                ),
            },
            {
              path: "journey",
              name: "journey",
              component: () =>
                import("@/pages/discover/components/chapters/D-journey.vue"),
            },
            {
              path: "nhrdn-agm",
              name: "nhrdn-agm",
              component: () =>
                import("@/pages/discover/components/chapters/D-agm.vue"),
            },
            {
              path: "code",
              name: "d-key-code",
              component: () =>
                import("@/pages/discover/components/chapters/D-Code.vue"),
            },
            {
              path: "our-people",
              name: "d-key-ourpeople",
              component: () =>
                import("@/pages/discover/components/chapters/D-OurPeople.vue"),
            },
            {
              path: "handbook",
              name: "d-key-handbook",
              component: () =>
                import("@/pages/discover/components/chapters/D-handbook.vue"),
            },
            {
              path: "mediaroom",
              name: "d-key-mediaroom",
              component: () =>
                import("@/pages/discover/components/chapters/D-mediaroom.vue"),
            },
            {
              path: "auditor-reports",
              name: "d-key-auditor",
              component: () =>
                import(
                  "@/pages/discover/components/chapters/D-auditorreports.vue"
                ),
            },
            {
              path: "academy",
              name: "d-key-academy",
              component: () =>
                import("@/pages/discover/components/chapters/D-academy.vue"),
            },

            // {
            //   path: "video-tutorial",
            //   name: "courseVideo",
            //   component: () =>
            //     import(
            //       "@/pages/e-learning/learningCourses/components/Video.vue"
            //     ),
            // },
          ],
        },

        //Initiatives
        {
          path: "initiatives",
          name: "initiatives",
          component: () => import("@/pages/initiatives/Initiatives.vue"),
          redirect: "/initiatives/newsletter",
          children: [
            {
              path: "newsletter",
              name: "int-newsletter",
              component: () =>
                import("@/pages/initiatives/components/Newsletter.vue"),
            },
            {
              path: "mind-master",
              name: "int-minmaster",
              component: () =>
                import("@/pages/initiatives/components/MindMaster.vue"),
            },
            {
              path: "mind-master1",
              name: "int-minmaster1",
              component: () =>
                import("@/pages/initiatives/components/MindMaster1.vue"),
            },
            {
              path: "mind-master3",
              name: "int-minmaster3",
              component: () =>
                import("@/pages/initiatives/components/Mindmaster3.vue"),
            },
            {
              path: "accreditation",
              name: "int-accreditation",
              component: () =>
                import("@/pages/initiatives/components/Accreditation.vue"),
            },
            {
              path: "research-publication",
              name: "int-research",
              component: () =>
                import("@/pages/initiatives/components/Research.vue"),
            },
            {
              path: "competitions",
              name: "int-challenge",
              component: () =>
                import("@/pages/initiatives/components/Competitions.vue"),
            },
            {
              path: "human-capital",
              name: "int-humancapital",
              component: () =>
                import("@/pages/initiatives/components/HumanCapital.vue"),
            },
            {
              path: "inclusion-lab",
              name: "int-inclusionlab",
              component: () =>
                import("@/pages/initiatives/components/InclusionLab.vue"),
            },
            {
              path: "about-mind",
              name: "int-aboutmind",
              component: () =>
                import("@/pages/initiatives/components/AboutMind.vue"),
            },
          ],
        },

        {
          path: "upskill/cart",
          name: "upskill-cart",
          component: () => import("@/pages/e-learning/cart/Cart.vue"),
        },

        // E-commerce
        {
          path: "e-commerce",
          name: "e-commerce",
          // component: () => import("@/pages/e-commerce/home/Home.vue"),
          // component: () => import("@/pages/e-commerce-1/home/Home.vue"),
          component: () =>
            import("@/pages/e-commerce-1/nightwear/Nightwear.vue"),
        },
        {
          path: "product",
          name: "Product",
          component: () => import("@/pages/e-commerce-1/product/Product.vue"),
        },
        {
          path: "e-commerce-checkout",
          name: "e-commerce-checkout",
          component: () => import("@/pages/e-commerce-1/checkout/Checkout.vue"),
        },
        {
          path: "e-commerce-confirmationpage",
          name: "e-commerce-confirmationpage",
          component: () =>
            import(
              "@/pages/e-commerce-1/confirmationpage/ConfirmationPage.vue"
            ),
        },
        {
          path: "myorders",
          name: "myorders",
          component: () => import("@/pages/e-commerce-1/myorders/MyOrders.vue"),
        },
        {
          path: "overview",
          name: "product-overview",
          component: () => import("@/pages/e-commerce/overview/Overview.vue"),
        },
        {
          path: "wishlist",
          name: "wishlist",
          component: () => import("@/pages/e-commerce/wishlist/Wishlist.vue"),
        },
        {
          path: "cart",
          name: "cart",
          component: () => import("@/pages/e-commerce/cart/Cart.vue"),
        },
        {
          path: "dia",
          name: "dia",
          component: () => import("@/pages/new/Dailog.vue"),
        },
        {
          path: "new",
          name: "new",
          component: () => import("@/pages/new/New.vue"),
        },

        {
          path: "talent-banner",
          name: "talent-banner",
          component: () => import("@/pages/talent-hub/TalentHubBanner.vue"),
        },
        {
          path: "checkout",
          name: "checkout",
          component: () => import("@/pages/e-commerce/checkout/Checkout.vue"),
        },
        {
          path: "payment",
          name: "payment",
          component: () => import("@/pages/e-commerce/paytment/Payment.vue"),
        },
        {
          path: "notification",
          name: "notification",
          component: () => import("@/pages/notification/Notification.vue"),
        },
        // {
        //   path: "product",
        //   name: "product",
        //   component: () => import("@/pages/e-commerce/product/Product.vue"),
        // },
        //Social Channel
        {
          path: "channel",
          name: "social-channel",
          component: () => import("@/channel/Channel.vue"),
          beforeEnter: (to, from, next) => {
            // const user = jwt.decode(localStorage.getItem("token"));
            if (
              auth.state.auth.user &&
              Object.keys(auth.state.auth.user).length
            ) {
              next();
            } else {
              alert("Please Login First!!");
              router.push({ name: "home" });
              next(false);
            }
          },
        },
      ],
    },
    // {
    //   path: "/",
    //   name: "e-commerce",
    //   component: () => import("@/main/Main.vue"),
    //   component: () => import("@/pages/e-commerce/home/Home.vue"),
    //   redirect: "channel-discussion",
    //   children: [
    //     {
    //       path: "e-com",
    //       name: "e-commerce",
    //       component: () => import("@/pages/e-commerce/home/Home.vue"),
    //     },
    //     {
    //       path: "overview",
    //       name: "product-overview",
    //       component: () => import("@/pages/e-commerce/overview/Overview.vue"),
    //     },
    //   ],
    // },
    {
      path: "/channel",
      name: "maindark",
      component: () => import("@/main/MainDark.vue"),
      redirect: "channel-discussion",
      children: [],
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/pages/login/LogIn.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("../pages/conference/components/Register.vue"),
    },
    {
      path: "/e_commerce_invoice",
      name: "e_commerce_invoice",
      component: () => import("@/main/ecom-Invoice.vue"),
      // redirect: "channel-discussion",
    },
  ],
});
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
