import RepositoryFactory from "@/apis";
const category = RepositoryFactory.ecommerce1.ProductCategory;

export default {
  GetCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.GetCategory(payload);
        commit("SET_CATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
