import Repository from "./Repository";

const GET_USER = "user";
const GET_CHANNEL = "channel";
const GET_POST = "post";
// const LOGIN = "loginWithPassword";
// const LOGOUT = "logout";
// const FORGOT_PASSWORD = "forgotPassword";
const GET_ADMIN_MEMBERS = "getAdminMembers";
const SEARCH_MEMBER = "searchMember";

export default {
  getUsers(payload) {
    return Repository.get(transformRoute(GET_USER), { params: payload });
  },

  getChannel(payload) {
    return Repository.get(transformRoute(GET_CHANNEL), { params: payload });
  },
  getPost(payload) {
    return Repository.get(transformRoute(GET_POST), { params: payload });
  },
  getAdminMembers(payload) {
    return Repository.post(transformRoute(GET_ADMIN_MEMBERS), {
      params: payload,
    });
  },
  searchMember(payload) {
    return Repository.post(transformRoute(SEARCH_MEMBER), {
      params: payload,
    });
  },
};

const transformRoute = (route) => {
  return `/admin/${route}`;
};
