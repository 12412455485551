import RepositoryFactory from "@/apis";
const user = RepositoryFactory.user;

export default {
  getSingleUser({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.getSingleUser(payload);

        commit("SET_USER_DETAILS", data.data[0]);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleUserForRegistration({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.getSingleUser(payload);

        commit("SET_USER_DETAILS", data.data[0]);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingle({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.getSingle(payload);

        commit("SET_USER_DETAILS", data.data[0]);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleforinvoice({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.getSingleforinvoice(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  inactiveUsers({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.inactiveUsers(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getInActiveUsers({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.getInActiveUsers(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deletedUsers({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.deletedUsers(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  ActivateUser({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.ActivateUser(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAll({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.getAll({ isActive: true, ...payload });

        commit("SET_ALL_USER_DETAILS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateUser({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.updateUser(payload);

        commit("SET_USER_DETAILS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  createUser({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.createUser(payload);

        commit("CREATE_USER_DETAILS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  searchResult({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.searchResult(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
