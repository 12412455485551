// import jwt from "jsonwebtoken";
import auth from "@/store";
import router from "@/router";
export default [
  {
    path: "/admin",
    name: "admin",
    component: () => import("../layouts/Admin.vue"),

    beforeEnter: (to, from, next) => {
      // const user = jwt.decode(localStorage.getItem("token"));
      if (
        [
          "admin",
          "channelAdmin",
          "author",
          "conferenceAdmin",
          "recruiterAdmin",
        ].includes(auth.state.auth.user.role)
      ) {
        next();
      } else {
        router.push({ name: "home" });
        next(false);
      }
    },
    children: [
      {
        path: "ecom-category",
        name: "ecom-category",
        component: () =>
          import("@/admin/pages/e-commerce-1/categories/Categories.vue"),
      },
      {
        path: "ecom-product",
        name: "ecom-product",
        component: () =>
          import("@/admin/pages/e-commerce-1/add-product/AdminAdd-Product.vue"),
      },
      {
        path: "page",
        name: "page",
        component: () => import("@/admin/pages/page/Page.vue"),
      },
      {
        path: "home-cms",
        name: "home-cms",
        component: () => import("@/admin/pages/page/components/Home.vue"),
      },
      {
        path: "about-cms",
        name: "about-cms",
        component: () => import("@/admin/pages/page/components/About.vue"),
      },
      {
        path: "linkgenerator",
        name: "linkgenerator",
        component: () =>
          import("@/admin/pages/page/components/LinkGenerator.vue"),
      },
      {
        path: "leadership-cms",
        name: "leadership-cms",
        component: () => import("@/admin/pages/page/components/Leadership.vue"),
      },
      {
        path: "award-cms",
        name: "award-cms",
        component: () => import("@/admin/pages/page/components/Award.vue"),
      },
      {
        path: "ecom-order",
        name: "ecom-order",
        component: () =>
          import("@/admin/pages/e-commerce-1/order-management/Categories.vue"),
      },
      {
        path: "ticker",
        name: "ticker",
        component: () => import("@/admin/pages/cms/Ticker.vue"),
      },
      {
        path: "ecom-news-letter",
        name: "ecom-news-letter",
        component: () =>
          import("@/admin/pages/e-commerce-1/news-letter/news-letter.vue"),
      },
      {
        path: "ecom-order-returns",
        name: "ecom-order-returns",
        component: () =>
          import("@/admin/pages/e-commerce-1/Order-Returns/OrderReturn.vue"),
      },
      {
        path: "ecom-order-coupon",
        name: "ecom-order-coupon",
        component: () =>
          import("@/admin/pages/e-commerce-1/coupon-management/CouponM.vue"),
      },
      {
        path: "blogs",
        name: "blogs",
        component: () => import("../pages/blog/Blog.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },

      {
        path: "podcasts",
        name: "podcasts",
        component: () => import("../pages/Podcast/podcasts.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "events-panel",
        name: "events-panel",
        component: () => import("../pages/events/Events.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },

      {
        path: "add-blogs",
        name: "addblogcontent",
        component: () => import("../pages/blog/Add.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "blog/edit",
        name: "social-admin-edit-blog",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-add-blog" */ "../pages/blog/Edit.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },

      {
        path: "/admin/selfhelp-panel/addPodcast",
        name: "addPodcast",
        component: () => import("../pages/Podcast/components/AddPodcast.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/selfhelp-panel/editPodcast",
        name: "editPodcast",
        component: () => import("../pages/Podcast/components/EditPodcast.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/selfhelp-panel/editNewsletter",
        name: "editNewsletter",
        component: () =>
          import("../pages/Podcast/components/EditNewsletter.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/events-panel/add-events",
        name: "addEvents",
        component: () => import("../pages/events/components/CreateEvent1.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/events-panel/edit-event",
        name: "editEvent",
        component: () => import("../pages/events/components/EditEvent.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-course",
        name: "admin-coursesdetails",
        component: () => import("../pages/add-course/Courses.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "coursecustomizedetails",
        name: "admin-coursecustomizedetails",
        component: () => import("../pages/add-course/components/Customize.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "Attendees",
        name: "admin-Attendees",
        component: () => import("../pages/add-course/components/Attendees.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "chapters",
        name: "get-chapters",
        component: () => import("../pages/channel/Channel.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "channeltable",
        name: "channeltable",
        component: () =>
          import("../../admin/pages/channel/components/ChanelTable.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "admindetails",
        name: "admindetails",
        component: () =>
          import("../../admin/pages/channel/components/AdminDetails.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },

      {
        path: "postcomments",
        name: "postcomments",
        component: () =>
          import("../../admin/pages/post/components/PostComment.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },

      {
        path: "posts",
        name: "posts",
        component: () => import("../pages/post/Post.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "careers-panel",
        name: "careers-panel",
        component: () => import("../pages/careers/ListCareer.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/career-panel/create-careers-content",
        name: "create-career",
        component: () => import("../pages/careers/components/CreateCareer.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/members",
        name: "get-members",
        component: () => import("../pages/members/MembersTable.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/plans",
        name: "get-plans",
        component: () => import("../pages/plans/PlansTable.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/benifits",
        name: "get-benifits",
        component: () => import("../pages/benifits/BenifitsTable.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/channels",
        name: "get-channel",
        component: () => import("../pages/channels/ChannelTable.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/members",
        name: "get-request",
        component: () =>
          import("../pages/membership-request/Membershiprequest.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/inactive",
        name: "get-inactive",
        component: () => import("../pages/Inactive-users/InactiveUsers.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/deleted",
        name: "get-deleted",
        component: () => import("../pages/deleted-users/deleted-users.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/members",
        name: "addrequest",
        component: () =>
          import("../pages/membership-request/components/AddRequest.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/payments",
        name: "get-payments",
        component: () => import("../pages/payments/PaymentsTable.vue"),

        // beforeEnter: (to, from, next) => {
        //   // const user = jwt.decode(localStorage.getItem("token"));
        //   if (["admin"].includes(auth.state.auth.user.role)) {
        //     next();
        //   } else {
        //     router.push({ name: "get-members" });
        //     next(false);
        //   }
        // },

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/decode",
        name: "decode",
        component: () => import("../pages/decode/Decode.vue"),

        // beforeEnter: (to, from, next) => {
        //   // const user = jwt.decode(localStorage.getItem("token"));
        //   if (["admin"].includes(auth.state.auth.user.role)) {
        //     next();
        //   } else {
        //     router.push({ name: "get-members" });
        //     next(false);
        //   }
        // },

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/job",
        name: "adminjob",
        component: () => import("../pages/job/Job.vue"),

        // beforeEnter: (to, from, next) => {
        //   // const user = jwt.decode(localStorage.getItem("token"));
        //   if (["admin"].includes(auth.state.auth.user.role)) {
        //     next();
        //   } else {
        //     router.push({ name: "get-members" });
        //     next(false);
        //   }
        // },

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/application",
        name: "adminapplication",
        component: () => import("../pages/application/Application.vue"),

        // beforeEnter: (to, from, next) => {
        //   // const user = jwt.decode(localStorage.getItem("token"));
        //   if (["admin"].includes(auth.state.auth.user.role)) {
        //     next();
        //   } else {
        //     router.push({ name: "get-members" });
        //     next(false);
        //   }
        // },

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/addjob",
        name: "adminaddjob",
        component: () => import("../pages/job/components/AddJob.vue"),

        // beforeEnter: (to, from, next) => {
        //   // const user = jwt.decode(localStorage.getItem("token"));
        //   if (["admin"].includes(auth.state.auth.user.role)) {
        //     next();
        //   } else {
        //     router.push({ name: "get-members" });
        //     next(false);
        //   }
        // },

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/editjob",
        name: "admineditjob",
        component: () => import("../pages/job/components/EditJob.vue"),

        // beforeEnter: (to, from, next) => {
        //   // const user = jwt.decode(localStorage.getItem("token"));
        //   if (["admin"].includes(auth.state.auth.user.role)) {
        //     next();
        //   } else {
        //     router.push({ name: "get-members" });
        //     next(false);
        //   }
        // },

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/category",
        name: "category",
        component: () => import("../pages/job/components/Category.vue"),

        // beforeEnter: (to, from, next) => {
        //   // const user = jwt.decode(localStorage.getItem("token"));
        //   if (["admin"].includes(auth.state.auth.user.role)) {
        //     next();
        //   } else {
        //     router.push({ name: "get-members" });
        //     next(false);
        //   }
        // },

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/subcategory",
        name: "subcategory",
        component: () => import("../pages/job/components/SubCategory.vue"),

        // beforeEnter: (to, from, next) => {
        //   // const user = jwt.decode(localStorage.getItem("token"));
        //   if (["admin"].includes(auth.state.auth.user.role)) {
        //     next();
        //   } else {
        //     router.push({ name: "get-members" });
        //     next(false);
        //   }
        // },

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/payment-decode",
        name: "payment-decode",
        component: () => import("../pages/decode/Payment.vue"),

        // beforeEnter: (to, from, next) => {
        //   // const user = jwt.decode(localStorage.getItem("token"));
        //   if (["admin"].includes(auth.state.auth.user.role)) {
        //     next();
        //   } else {
        //     router.push({ name: "get-members" });
        //     next(false);
        //   }
        // },

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/brouchers-decode",
        name: "brouchers-decode",
        component: () => import("../pages/brouchers/Brouchers.vue"),

        // beforeEnter: (to, from, next) => {
        //   // const user = jwt.decode(localStorage.getItem("token"));
        //   if (["admin"].includes(auth.state.auth.user.role)) {
        //     next();
        //   } else {
        //     router.push({ name: "get-members" });
        //     next(false);
        //   }
        // },

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      // {
      //   path: "/admin/cms-decode",
      //   name: "cms-decode",
      //   component: () => import("../../pages/cms-conference/Cms.vue"),

      //   // beforeEnter: (to, from, next) => {
      //   //   // const user = jwt.decode(localStorage.getItem("token"));
      //   //   if (["admin"].includes(auth.state.auth.user.role)) {
      //   //     next();
      //   //   } else {
      //   //     router.push({ name: "get-members" });
      //   //     next(false);
      //   //   }
      //   // },

      //   meta: {
      //     rule: "editor",
      //     authRequired: true,
      //   },
      // },
      // {
      //   path: "/admin/category",
      //   name: "cms-category",
      //   component: () => import("../../pages/cms-conference/Category.vue"),

      //   // beforeEnter: (to, from, next) => {
      //   //   // const user = jwt.decode(localStorage.getItem("token"));
      //   //   if (["admin"].includes(auth.state.auth.user.role)) {
      //   //     next();
      //   //   } else {
      //   //     router.push({ name: "get-members" });
      //   //     next(false);
      //   //   }
      //   // },

      //   meta: {
      //     rule: "editor",
      //     authRequired: true,
      //   },
      // },
      {
        path: "/admin/bestofbreed",
        name: "breed-admin",
        component: () => import("../pages/best/Breed.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/payment-bestofbreed",
        name: "payment-best",
        component: () => import("../pages/best/Payment.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/corporate-coupon",
        name: "corporate",
        component: () => import("../pages/corporate/Corporate.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/coupon-code",
        name: "coupon-code",
        component: () => import("../pages/coupon/Coupon.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/adduser",
        name: "adduser",
        component: () => import("../pages/members/AddUser.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "/admin/memberships",
        name: "get-memberships",
        component: () => import("../pages/memberships/Memberships.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
    ],
    meta: {
      rule: "editor",
      authRequired: true,
    },
  },
];
