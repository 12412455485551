import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const getindustry = "getindustry";
const getsubindustry = "getsubindustry";
const createJobCategory = "createJobCategory";
const updateJobCategory = "updateJobCategory";
const updateSubJobCategory = "updateSubJobCategory";
const deleteJobCategory = "deleteJobCategory";
const deleteSubJobCategory = "deleteSubJobCategory";
const createSubJobCategory = "createSubJobCategory";
const getJobCategory = "getJobCategory";
const getSubJobCategory = "getSubJobCategory";

// console.warn('dkajfkadflsdfjklsf', jobCreate);

export default {
  createJobCategory(payload) {
    return Repository.post(transformRoute(createJobCategory), payload);
  },
  updateJobCategory(payload) {
    return Repository.post(transformRoute(updateJobCategory), payload);
  },
  updateSubJobCategory(payload) {
    return Repository.post(transformRoute(updateSubJobCategory), payload);
  },
  deleteJobCategory(payload) {
    return Repository.post(transformRoute(deleteJobCategory), payload);
  },
  deleteSubJobCategory(payload) {
    return Repository.post(transformRoute(deleteSubJobCategory), payload);
  },
  createSubJobCategory(payload) {
    return Repository.post(transformRoute(createSubJobCategory), payload);
  },
  getJobCategory(payload) {
    return Repository.post(transformRoute(getJobCategory), payload);
  },
  getSubJobCategory(payload) {
    return Repository.post(transformRoute(getSubJobCategory), payload);
  },
  getindustry(payload) {
    return Repository.post(transformRoute(getindustry));
  },
  getsubindustry(payload) {
    return Repository.post(transformRoute(getsubindustry), payload);
  },
};

const transformRoute = (route) => {
  return `nhrdn/master/${route}`;
};
