export default {
  SET_ALL_MEMBERSHIPS(state, payload) {
    state.allMemberships = payload;
  },
  SET_SELECTED_MEMBERSHIP(state, payload) {
    state.selectedMembership = payload;
  },
  GET_SINGLE_MEMBERSHIPS(state, payload) {
    state.singleMembership = payload;
  },
};
