export default {
  selectedChannel: [],
  channels: [],
  selectedChannelMembers: [],
  selectedChannelJoinRequest: [],
  search: "",
  select_channel: {},
  getMemberById: [],
  postByUser: [],
  getUSerchannelfeed: [],
  channelCategories: [],
  channelInCategory: [],
  keyMembers: [],
  memberbyChannel: [],
  searchText: "",
  channel : {}
};
