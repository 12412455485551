// import channel from "./channel";
import ProductCategory from "./category";
import products from "./product";
import auth from "./auth";
import ecommerce from "./ecommerce";
import cart from "./User/Cart";
import Address from "./User/Address";
import UserAuth from "./User/currentUser";
import Order from "./User/Order";
import Wishlist from "./User/Wishlist";
import sizeVariant from "./Admin/sizeVariant";
import Dispatch from "./Admin/Dispatch";
import Testimonials from "./Admin/Testimonials";
import banner from "./Admin/Banner";
import Gallery from "./Admin/gallery";
import News from "./Admin/News";
import Category from "./Admin/Category";
import Subcategory from "./Admin/Subcategory";
import Productadmin from "./Admin/Products";
import colorVariant from "./Admin/colorVariant";
import Diamond from "./Admin/Diamond";
import Taxes from "./Tax";
import Coupon from "./Coupon";
import OrderReturn from "./User/OrderReturn";
import contactUs from "./Admin/ContactUs";
import Transaction from "./Admin/Transaction";
import newsLetter from './Admin/newsLetter'
export default {
  category: Category,
  product: Productadmin,
  auth: auth,
  ecommerce: ecommerce,
  cart: cart,
  Address: Address,
  UserAuth: UserAuth,
  order: Order,
  Wishlist: Wishlist,
  Diamond,
  colorVariant,
  products,
  Subcategory,
  Category,
  ProductCategory,
  News,
  Gallery,
  Testimonials,
  Dispatch,
  sizeVariant,
  banner,
  Tax: Taxes,
  coupon: Coupon,
  OrderReturn,
  contactUs,
  Transaction,newsLetter
};
